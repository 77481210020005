import { PerDiemItem } from './per-diem-item';
import { DataRecord } from 'accorto';
import { CResponse } from 'accorto';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: CResponseTrack
 */
export class CResponseTrack extends CResponse {

  /**
   * label: Per Diem Response Info
   */
  public perDiemItems: PerDiemItem[] = [];

  /**
   * e.g. Name ASC
   * label: Primary Sort
   */
  public primarySort: string;

  /**
   * label: TE Item Records for startTime
   */
  public records: DataRecord[] = [];

  /**
   * label: Start Time ms
   */
  public startTimeMs: number;

  /**
   * Expenses until
   * label: End Time ms
   */
  public endTimeMs: number;

} // CResponseTrack
