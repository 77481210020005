import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 't4d-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {

  loginHeading: SafeHtml;

  constructor(sanitizer: DomSanitizer) {
    this.loginHeading = sanitizer.bypassSecurityTrustHtml('Track 4<sup>D</sup> - Login');
  } // constructor

} // LoginComponent
