/* tslint:disable max-line-length no-inferrable-types */
export var SsoProvider;
(function (SsoProvider) {
    SsoProvider["Google"] = "Google";
    SsoProvider["None"] = "None";
    SsoProvider["Salesforce"] = "Salesforce";
})(SsoProvider || (SsoProvider = {}));
const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** TenantUser Definition */
export const TenantUserD = {
    recordTypeName: 'TenantUser',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    firstName: {
        n: 'firstName',
        t: 'string'
    },
    email: {
        n: 'email',
        t: 'string'
    },
    isEmailConfirmed: {
        n: 'isEmailConfirmed',
        t: 'boolean'
    },
    isAdmin: {
        n: 'isAdmin',
        t: 'boolean'
    },
    isManager: {
        n: 'isManager',
        t: 'boolean'
    },
    ssoProvider: {
        n: 'ssoProvider',
        t: 'SsoProvider'
    },
    photoUrl: {
        n: 'photoUrl',
        t: 'string'
    },
    sfUserName: {
        n: 'sfUserName',
        t: 'string'
    },
    sfResourceId: {
        n: 'sfResourceId',
        t: 'string'
    },
    sfOrgId: {
        n: 'sfOrgId',
        t: 'string'
    },
    sfThumbnail: {
        n: 'sfThumbnail',
        t: 'string'
    },
    proxyUser: {
        n: 'proxyUser',
        t: 'TenantUserI'
    },
    proxyUserId: {
        n: 'proxyUserId',
        t: 'number|string'
    },
    proxyUserSfId: {
        n: 'proxyUserSfId',
        t: 'string'
    },
    addrStreet: {
        n: 'addrStreet',
        t: 'string'
    },
    addrCity: {
        n: 'addrCity',
        t: 'string'
    },
    addrState: {
        n: 'addrState',
        t: 'string'
    },
    addrZip: {
        n: 'addrZip',
        t: 'string'
    },
    addrCountry: {
        n: 'addrCountry',
        t: 'string'
    },
    phone: {
        n: 'phone',
        t: 'string'
    },
    mobilePhone: {
        n: 'mobilePhone',
        t: 'string'
    },
    locale: {
        n: 'locale',
        t: 'string'
    },
    firstDayOfWeek: {
        n: 'firstDayOfWeek',
        t: 'number'
    },
    language: {
        n: 'language',
        t: 'string'
    },
    timezone: {
        n: 'timezone',
        t: 'string'
    },
    utcOffset: {
        n: 'utcOffset',
        t: 'number'
    },
}; // TenantUser
export { ɵ0 };
