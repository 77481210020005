import { createAction, props } from '@ngrx/store';
import { CResponseTrack } from '../model/c-response-track';

export enum TrackItemActionTypes {
  TrackRequest = '[Track] Request',
  TrackResponse = '[Track] Response',
  TrackError = '[Track] Error'

}

/**
 * Initial created in LoginDeactivateGuard
 */
export const trackItemRequest = createAction(
  TrackItemActionTypes.TrackRequest,
  props<{ startTimeMs: number }>()
);

export const trackItemResponse = createAction(
  TrackItemActionTypes.TrackResponse,
  props<{ response: CResponseTrack }>()
);

export const trackItemError = createAction(
  TrackItemActionTypes.TrackError,
  props<{ err: any }>()
);
