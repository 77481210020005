import { Component, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { DomSanitizer, Meta, MetaDefinition, SafeHtml, Title } from '@angular/platform-browser';
import {
  AccortoService,
  appHeading,
  GoogleAnalyticsService,
  loginRequestAction,
  LoginState,
  MenuItem,
  selectAppStatus,
  selectLoginState
} from 'accorto';
import { AppState } from './reducers';

/**
 * Track 4D
 */
@Component({
  selector: 't4d-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  appTitle = 'Track 4D';
  appName: SafeHtml;

  menuItems: MenuItem[] = [];

  footerUrl: string = '-';
  footerStatus: string = '-';

  /**
   * Track 4D
   */
  constructor(private store: Store<AppState>,
              private router: Router,
              private pageTitle: Title,
              private pageMeta: Meta,
              private conf: AccortoService,
              sanitizer: DomSanitizer,
              ga: GoogleAnalyticsService) {
    this.appName = sanitizer.bypassSecurityTrustHtml('Track 4<sup>D</sup>');

    this.store.dispatch(loginRequestAction({ request: undefined }));
    // login changes
    this.store.pipe(select(selectLoginState))
      .subscribe((state: LoginState) => {
        // console.log('T4d.selectLoginState', state);
        this.setMenu();
      });

    this.routerTrackTitle();
  } // constructor

  /**
   * Set Menu after login
   */
  private setMenu() {
    let perDiem = '-';
    if (this.conf && this.conf.session && this.conf.session.settings) {
      perDiem = this.conf.session.settings.perDiem;
      if (!perDiem) {
        perDiem = '-';
      }
    }

    this.menuItems = [];
    // Menu
    let mi = new MenuItem()
      .setName('time', 'Time', 'Capture Time',
        '/assets/accorto/timeCapture-100.svg')
      .setNav('/time');
    this.menuItems.push(mi);
    mi = new MenuItem()
      .setName('expense', 'Expense', 'Capture Expenses',
        '/assets/accorto/expenseCapture-100.svg')
      .setNav('/expense');
    this.menuItems.push(mi);

    mi = new MenuItem()
      .setName('in-out', 'Check In/Out', 'Check In/Out',
        '/assets/accorto/timeInOut-100.svg')
      .setNav('/in-out');
    this.menuItems.push(mi);

    if (this.conf.isDebug || perDiem.includes('gsa')) {
      mi = new MenuItem()
        .setName('per-diem', 'Per Diem', 'Enter Per Diems',
          '/assets/accorto/perDiem-100.svg')
        .setNav('/per-diem');
      this.menuItems.push(mi);
    }
    if (this.conf.isDebug || perDiem.includes('de')) {
      mi = new MenuItem()
        .setName('per-diem-de', 'Reisekosten', '',
          '/assets/accorto/perDiem-100.svg')
        .setNav('/per-diem-de');
      this.menuItems.push(mi);
    }
    /* if (this.conf.isDebug) {
      mi = new MenuItem()
        .setName('track', 'Timekeeper', 'TimeKeeper',
          '/assets/accorto/timeKeeper-100.svg')
        .setNav('/track');
      this.menuItems.push(mi);
    } */
    mi = new MenuItem()
      .setName('timesheet', 'Timesheet', 'Weekly Timesheet',
        '/assets/accorto/timeSheet-100.svg')
      .setNav('/timesheet');
    this.menuItems.push(mi);
    mi = new MenuItem()
      .setName('expense-table', 'Expenses', 'Expense Table',
        '/assets/accorto/expenseSheet-100.svg')
      .setNav('/expense-table');
    this.menuItems.push(mi);
  } // setMenu

  /**
   * Set PageTitle, PageMeta, Footer
   */
  private routerTrackTitle() {
    // route changed - update header
    this.router.events.pipe(
      filter(event => event instanceof RoutesRecognized)
    ).subscribe((rr: RoutesRecognized) => {
      const theTitle = rr.state.root.firstChild.data.title;
      const theDescription = rr.state.root.firstChild.data.description;
      const theKeywords = rr.state.root.firstChild.data.keywords;
      //
      if (theTitle) {
        this.pageTitle.setTitle(theTitle + ' | Track 4D (Time)');
        this.store.dispatch(appHeading({ heading: theTitle }));
      }
      //
      if (theDescription) {
        const description: MetaDefinition = {
          name: 'description',
          content: theDescription
        };
        this.pageMeta.updateTag(description);
      }
      if (theKeywords) {
        const keywords: MetaDefinition = {
          name: 'keywords',
          content: theKeywords
        };
        this.pageMeta.updateTag(keywords);
      }
    }); // route

    // update footer - based on router
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((rr: NavigationEnd) => {
      this.footerUrl = rr.urlAfterRedirects;
      const index = this.footerUrl.indexOf('?');
      if (index > 0) { // remove query parameters
        this.footerUrl = this.footerUrl.substring(0, index);
      }
    });
    // footer status - this.store.dispatch(appStatus({ status: 'xx' }));
    this.store.pipe(
      select(selectAppStatus)
    ).subscribe((status) => {
      this.footerStatus = status;
    });
  } // routerTrackTitle

} // AppComponent
