const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** FinancialAccount Definition */
export const FinancialAccountD = {
    recordTypeName: 'FinancialAccount',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    code: {
        n: 'code',
        t: 'string'
    },
    isExpenseReport: {
        n: 'isExpenseReport',
        t: 'boolean',
        d: true
    },
    fixedAmount2: {
        n: 'fixedAmount2',
        t: 'number'
    },
    isPerDiem: {
        n: 'isPerDiem',
        t: 'boolean'
    },
    perDiemType: {
        n: 'perDiemType',
        t: 'string'
    },
}; // FinancialAccount
export { ɵ0 };
