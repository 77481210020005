/**
 * Modal Info
 */
export class ModalInfo {

  /** Yes */
  public yesName: string = 'confirm';
  public yesLabel: string = 'Yes';
  yesClass: string = 'slds-button slds-button_brand';
  yesIsDestructive: boolean = false;

  /** No */
  public noName: string = 'reject';
  public noLabel: string = 'No';
  // noClass: string = 'slds-button slds-button_neutral';

  /** Cancel */
  public cancelName: string = 'cancel';
  public cancelLabel: string = 'Cancel';
  public showCancel: boolean = false;

  messages: string[] = [];

  /**
   * technical function calling business function
   * - set in ModalService - do not set here
   */
  public yesFn: () => void;
  public noFn: () => void;
  public cancelFn: () => void;

  constructor(public title: string, message: string, message2?: string, message3?: string) {
    this.messages.push(message);
    if (message2) {
      this.messages.push(message2);
      if (message3) {
        this.messages.push(message3);
      }
    }
  }

  /**
   * @param label yes label
   */
  setYesDestructive(label?: string) {
    this.yesIsDestructive = true;
    if (label) {
      this.yesLabel = label;
    }
    this.yesClass = 'slds-button slds-button_destructive';
  }

} // ModalInfo

