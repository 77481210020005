import { CResponse } from 'accorto';
/* tslint:disable max-line-length no-inferrable-types */
/**
 * extends: CResponse
 * name: CResponseTrack
 */
export class CResponseTrack extends CResponse {
    constructor() {
        super(...arguments);
        /**
         * label: Per Diem Response Info
         */
        this.perDiemItems = [];
        /**
         * label: TE Item Records for startTime
         */
        this.records = [];
    }
} // CResponseTrack
