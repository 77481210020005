import { CRequest } from 'accorto';
/* tslint:disable max-line-length no-inferrable-types */
export var Track4dType;
(function (Track4dType) {
    Track4dType["CHECKIN"] = "CHECKIN";
    Track4dType["DELETE"] = "DELETE";
    Track4dType["EXPENSES"] = "EXPENSES";
    Track4dType["PERDIEM"] = "PERDIEM";
    Track4dType["QUERY"] = "QUERY";
    Track4dType["SAVE"] = "SAVE";
    Track4dType["STAT"] = "STAT";
    Track4dType["SUBMIT"] = "SUBMIT";
})(Track4dType || (Track4dType = {}));
/**
 * extends: CRequest
 * name: CRequestTrack
 */
export class CRequestTrack extends CRequest {
    constructor() {
        super(...arguments);
        /**
         * ColumnName ASC|DESC NULLS FIRST|LAST
         * label: Order By
         */
        this.orderBys = [];
        /**
         * label: Per Diem Request Info
         */
        this.perDiemItems = [];
        /**
         * label: Records to save/delete
         */
        this.records = [];
    }
} // CRequestTrack
