import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AccortoService, DataRecord, Logger, ModelUtil, NotificationService, UiTab } from 'accorto';
import { TrackItemService } from '../track-item/track-item.service';
import { FormBuilder } from '@angular/forms';
import { CResponseTrack } from '../model/c-response-track';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TEItemUtil } from '../model/t-e-item-util';
import { Track4dType } from '../model/c-request-track';

/**
 * In/Out
 * TODO popup with link where to find record
 */
@Component({
  selector: 't4d-in-out',
  templateUrl: './in-out.component.html',
  styleUrls: [ './in-out.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class InOutComponent implements OnInit, OnDestroy {

  message: string;
  error: string;

  records: DataRecord[] = [];

  busy: boolean = true;
  ui: UiTab;

  private log: Logger = new Logger('InOut');
  private subscriptions: Subscription[] = [];
  private originalUi: UiTab;
  private position: Position;

  /**
   * In/Out
   */
  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private conf: AccortoService,
              private service: TrackItemService,
              private notify: NotificationService) {
  } // constructor

  getSeconds(record: DataRecord): number {
    const timeStart = record.value('timeStart');
    if (timeStart) {
      const timeMs = Number(timeStart);
      const secs = Math.round((Date.now() - timeMs) / 1000);
      this.log.debug('getSeconds ' + secs, record)();
      return secs;
    }
    return undefined;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit() {
    this.onRefresh(); // query data
    this.subscriptions.push(this.route.data.subscribe((data => {
      const ui = data.uiTab;
      // this.log.debug('uiTab', ui)();
      if (ui) {
        this.originalUi = ui;
        this.ui = TEItemUtil.cloneUi(this.originalUi, 'inout',
          true, this.conf.session.settings);
        this.ui.label = 'In/Out';
        this.busy = false;
      }
    })));

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos: Position) => {
          this.log.debug('geoLocation', pos)();
          this.position = pos;
        },
        (error) => {
          this.log.debug('geoLocation error', error)();
        },
        {});
    }
  } // ngOnInit

  onNew() {
    let rowNo = -1;
    this.records.forEach((rec) => {
      if (!rec.id) {
        rowNo -= 1;
      }
    });
    const record = ModelUtil.newDataRecord(this.ui, rowNo);
    this.records.unshift(record); // on top
  } // onNew

  onRefresh() {
    this.busy = true;
    this.message = 'updating ...';
    this.error = undefined;
    const unsaved: DataRecord[] = [];
    for (const rec of this.records) {
      if (!rec.id) {
        unsaved.push(rec);
      }
    }
    this.service.queryInOut()
      .subscribe((response: CResponseTrack) => {
        this.log.debug('onRefresh.result', response);
        this.error = response.error;
        this.message = response.message;
        // records
        this.records = unsaved;
        for (const rec of response.records) {
          this.records.push(rec);
        }
        this.busy = false;
      });
  } // onRefresh

  /**
   * Save Record - called from Form/Record
   * @param record record to be saved
   */
  saveRecord(record: DataRecord) {
    if (this.position) {
      if (record.value('longitude') === undefined) {
        record.changeMap.longitude = String(this.position.coords.longitude);
        record.changeMap.latitude = String(this.position.coords.latitude);
      }
    }
    this.log.info('saveRecord', record, this.position)();
    this.message = 'saving ...';
    this.error = undefined;
    this.busy = true;
    const unsaved: DataRecord[] = [];
    for (const rec of this.records) {
      if (!rec.id && rec.rowNo !== record.rowNo) {
        unsaved.push(rec);
      }
    }
    this.service.saveRecord(record, Track4dType.CHECKIN)
      .subscribe((response: CResponseTrack) => {
        this.message = response.message;
        this.error = response.error;
        this.saveRecordResult(record, response.records);
        // records
        this.records = unsaved;
        for (const rec of response.records) {
          this.records.push(rec);
        }
        this.log.debug('saveRecord.result', this.records)();
        this.busy = false;
      });
  } // saveRecord

  /**
   * Notify user if In/Out is complete
   * @param record record (to be saved)
   * @param resultRecords save results
   */
  saveRecordResult(record: DataRecord, resultRecords: DataRecord[]) {
    if (this.error) {
      return; // we have an error
    }
    let isComplete = resultRecords.length === 0;
    if (!isComplete) {
      isComplete = true;
      for (const rr of resultRecords) {
        if (record.id === rr.id) {
          isComplete = false; // found
        }
      }
    }
    if (isComplete) {
      if (record.id) {
        this.notify.addSuccess('In/Out ' + record.name + ' completed',
          'T&E Item saved');
      } else {
        this.notify.addSuccess('In/Out completed',
          'T&E Item created');
      }
    }
  } // saveRecordComplete

  statusUpdate(msg: string) {
    this.log.info('statusUpdate', msg)();
    this.message = msg;
  }

} // InOut
