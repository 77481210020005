import { Tenant } from './tenant';
import { TenantUserI, TenantUserD } from './tenant-user-i';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* tslint:disable max-line-length no-inferrable-types */

export enum SsoProvider {
  Google = 'Google',
  None = 'None',
  Salesforce = 'Salesforce'
}

/**
 * name: TenantUser
 */
export class TenantUser {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id: number|string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created: number;

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy: TenantUser;

  public createdById: number;

  /**
   * label: Updated Time
   */
  public updated: number;

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy: TenantUser;

  public updatedById: number;

  /**
   * label: External Id
   */
  public externalId: string;

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant: Tenant;

  public tenantId: number;

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner: TenantUser;

  public ownerId: number;

  /**
   * label: Record Version
   */
  public version: number;

  /**
   * label: Salesforce Id
   */
  public sfId: string;

  public firstName: string;

  public email: string;

  /**
   * label: User Email confirmed
   */
  public isEmailConfirmed: boolean;

  /**
   * label: Administrator
   */
  public isAdmin: boolean;

  /**
   * label: Manager
   */
  public isManager: boolean;

  public ssoProvider: SsoProvider;

  public photoUrl: string;

  /**
   * label: Salesforce User Name
   */
  public sfUserName: string;

  /**
   * label: Salesforce Resource Id
   */
  public sfResourceId: string;

  /**
   * label: Salesforce Org Id
   */
  public sfOrgId: string;

  /**
   * label: Salesforce Thumbnail
   */
  public sfThumbnail: string;

  /**
   * fk TenantUser
   * label: Proxy User
   */
  public proxyUser: TenantUser;

  public proxyUserId: number;

  /**
   * label: Salesforce Proxy User
   */
  public proxyUserSfId: string;

  /**
   * label: Street
   */
  public addrStreet: string;

  /**
   * label: City
   */
  public addrCity: string;

  /**
   * label: State/Province
   */
  public addrState: string;

  /**
   * label: Zip/Postal Code
   */
  public addrZip: string;

  /**
   * label: Country
   */
  public addrCountry: string;

  public phone: string;

  public mobilePhone: string;

  /**
   * label: User Locale
   */
  public locale: string;

  /**
   * 1..7 = Sun..Sat
   * label: First Day of Week
   */
  public firstDayOfWeek: number;

  public language: string;

  /**
   * label: Timezone
   */
  public timezone: string;

  /**
   * label: Timezone Offset (s)
   */
  public utcOffset: number;


  /**
   * @return TenantUser record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'TenantUser';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key) => {
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): TenantUser {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        const value = record.valueMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, TenantUserD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        const value = record.changeMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, TenantUserD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: TenantUserI): TenantUser {
    Object.keys(TenantUserD).forEach((key) => {
      const value = record[ key ];
      if (value) {
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'proxyUser') { // fk
          this.proxyUser = new TenantUser();
          this.proxyUser.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // TenantUser
