import { CResponseI } from './c-response-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CResponse
 */
export class CResponse implements CResponseI {

  /**
   * label: Internal Category
   */
  public category: string;

  /**
   * label: Internal debug info
   */
  public debugInfo: string;

  /**
   * label: Error Message
   */
  public error: string;

  /**
   * label: Logged out / Session expired
   */
  public isLoggedOut: boolean;

  /**
   * label: General Message
   */
  public message: string;

  /**
   * label: request id
   */
  public rid: number;

} // CResponse
