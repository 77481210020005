import {
  DataColumn,
  DataRecord,
  DataTable,
  DataType,
  DateUtil,
  Logger,
  ModelUtil,
  Trl,
  UiFormField,
  UiFormSection,
  UiGridField,
  UiTab
} from 'accorto';
import { BarChartStackedData } from '../d3/bar-chart-stacked/bar-chart-stacked.data';
import { PieChartData } from '../d3/pie-chart/pie-chart.data';
import { TEItemD } from './t-e-item-i';

/**
 * TE Item Utilities
 */
export class TEItemUtil {

  private static log: Logger = new Logger('TEItemUtil');

  /**
   * Clone UI
   * @param ui original
   * @param what - ts|et|time|exp|inout|ref - als used as html id
   * @param isSf use Sf fields
   * @param settings tenant/session settings
   * @param showWeekends timesheet with weekends
   * @param startDate timesheet start date
   */
  static cloneUi(ui: UiTab, what: string,
                 isSf: boolean, settings: { [ key: string ]: string },
                 showWeekends: boolean = false, startDate?: Date
  ): UiTab {

    const rv = ModelUtil.cloneUi(ui);
    rv.name = ui.name;

    //
    let shPj = true;
    let shPjPh = true;
    let shPjLn = true;
    let shAcct = false;
    let shCont = false;
    let shAT = true;
    let shInv = false;
    if (settings) {
      if (settings.shPj) {
        shPj = settings.shPj === 'true';
      }
      if (settings.shPjPh) {
        shPjPh = settings.shPjPh === 'true';
      }
      if (settings.shPjLn) {
        shPjLn = settings.shPjLn === 'true';
      }
      if (settings.shAcct) {
        shAcct = settings.shAcct === 'true';
      }
      if (settings.shCont) {
        shCont = settings.shCont === 'true';
      }
      if (settings.shAT) {
        shAT = settings.shAT === 'true';
      }
      if (settings.shInv) {
        shInv = settings.shInv === 'true';
      }
    }

    if (what === 'ts') { // timesheet
      TEItemUtil.cloneUiTs(ui, rv, isSf,
        shPj, shPjPh, shPjLn, shAcct, shCont, shAT, shInv,
        startDate, showWeekends);
    } else if (what === 'et') { // expense
      TEItemUtil.cloneUiEt(ui, rv, isSf,
        shPj, shPjPh, shPjLn, shAcct, shCont, shAT, shInv);
    } else { // time
      TEItemUtil.cloneUiSingle(ui, rv, isSf, what,
        shPj, shPjPh, shPjLn, shAcct, shCont, shAT, shInv);
    }
    // console.log('TeItemUtil.cloneUi', rv);
    return rv;
  } // cloneUi

  /**
   * Get Number or 0
   * @param record data record
   * @param key propertyName
   */
  static getNumber(record: DataRecord, key: string): number {
    const stringValue = record.value(key);
    if (stringValue && stringValue.length > 0) {
      const value = Number(stringValue);
      if (!Number.isNaN(value)) {
        return value;
      }
    }
    return 0;
  }

  /**
   * Check Time Record and move to start date
   * @param record the record
   * @param startDate start date
   * @return updated record
   */
  public static toStartDate(record: DataRecord, startDate: Date): DataRecord {
    const teDateString = record.value(TEItemD.teDate.n);
    let teDateTime = Number(teDateString);

    if (startDate.getTime() > teDateTime) { // after: 05-12 > 05-15
      this.log.warn('toStartDate ' + record.name, startDate.toISOString()
        + ' after ' + new Date(teDateTime).toISOString(), record)();
      return record;
    }
    if (startDate.getTime() > teDateTime) { // beyond: 05-12 > 05-15
      this.log.warn('toStartDate ' + record.name, startDate.toISOString()
        + ' beyond ' + new Date(teDateTime).toISOString(), record)();
      return record;
    }

    const hoursString = record.valueMap.hours;
    if (!hoursString || hoursString === '') {
      this.log.warn('toStartDate ' + record.name, 'NoHours', record)();
      return record;
    }
    const hours = Number(hoursString);

    let x1 = TEItemUtil.getHours(record, 1);
    let x2 = TEItemUtil.getHours(record, 2);
    let x3 = TEItemUtil.getHours(record, 3);
    let x4 = TEItemUtil.getHours(record, 4);
    let x5 = TEItemUtil.getHours(record, 5);
    let x6 = TEItemUtil.getHours(record, 6);
    let x7 = TEItemUtil.getHours(record, 7);

    const xSum = x1 + x2 + x3 + x4 + x5 + x6 + x7;
    if (xSum === 0) {
      if (hours === 0) {
        return record; // expense or no hours
      }
      record.changeMap.x1 = String(hours);
      // this.log.debug('toStartDate ' + record.name, 'set x1 to hours=' + hours, record)();
    } else if (xSum !== hours) {
      this.log.warn('toStartDate ' + record.name, 'hours=' + hours + ' <> sum=' + xSum, record)();
    }

    // one day back - hours to next day
    while (teDateTime !== startDate.getTime()) {
      teDateTime -= DateUtil.ONEDAY;
      record.changeMap.teDate = String(teDateTime); // back

      x1 = TEItemUtil.getHours(record, 1); // rotate
      x2 = TEItemUtil.getHours(record, 2);
      x3 = TEItemUtil.getHours(record, 3);
      x4 = TEItemUtil.getHours(record, 4);
      x5 = TEItemUtil.getHours(record, 5);
      x6 = TEItemUtil.getHours(record, 6);
      x7 = TEItemUtil.getHours(record, 7);

      record.changeMap.x1 = String(x7);
      record.changeMap.x2 = String(x1);
      record.changeMap.x3 = String(x2);
      record.changeMap.x4 = String(x3);
      record.changeMap.x5 = String(x4);
      record.changeMap.x6 = String(x5);
      record.changeMap.x7 = String(x6);

      // this.log.debug('toStartDate ' + record.name, new Date(teDateTime).toISOString(), record)();
    }
    record.changeMap.x0 = '0'; // temp
    return record;
  }

  /**
   * Update Chart Data based on item list
   * (called from HomeComponent)
   * @param records data records
   * @param projectData updated
   * @param projectMap reference
   * @param activityData updated
   * @param activityMap reference
   * @param expenseData updated
   * @param finAccountMap reference
   */
  public static update(records: DataRecord[],
                       projectData: BarChartStackedData,
                       projectMap: Map<string, string>,
                       activityData: BarChartStackedData,
                       activityMap: Map<string, string>,
                       expenseData: PieChartData,
                       finAccountMap: Map<string, string>
  ) {
    projectData.clear();
    activityData.clear();
    expenseData.clear();

    // this.log.info('update #' + this.itemList.length)();
    if (!records) {
      return;
    }
    // this.log.debug('update ' + startDate.toISOString())();

    for (const record of records) {
      // TEItemUtil.toStartDate(record, startDate);
      const hours = TEItemUtil.getNumber(record, TEItemD.teDate.n);
      if (hours) {
        const teDateString = record.value(TEItemD.teDate.n);
        const teDate = new Date(Number(teDateString));
        const localDate = new Date(teDate.getUTCFullYear(), teDate.getUTCMonth(), teDate.getUTCDate());
        // this.log.debug('update ' + record.name, hours, teDate.toISOString(), localDate.toISOString())();
        TEItemUtil.updateDay(localDate, 0, TEItemUtil.getNumber(record, 'x1'), record, projectData, activityData);
        TEItemUtil.updateDay(localDate, 1, TEItemUtil.getNumber(record, 'x2'), record, projectData, activityData);
        TEItemUtil.updateDay(localDate, 2, TEItemUtil.getNumber(record, 'x3'), record, projectData, activityData);
        TEItemUtil.updateDay(localDate, 3, TEItemUtil.getNumber(record, 'x4'), record, projectData, activityData);
        TEItemUtil.updateDay(localDate, 4, TEItemUtil.getNumber(record, 'x5'), record, projectData, activityData);
        TEItemUtil.updateDay(localDate, 5, TEItemUtil.getNumber(record, 'x6'), record, projectData, activityData);
        TEItemUtil.updateDay(localDate, 6, TEItemUtil.getNumber(record, 'x7'), record, projectData, activityData);
      } // hours

      const receiptAmount = TEItemUtil.getNumber(record, 'expenseAmount');
      if (receiptAmount) {
        const finAccountId = record.value('financialAccountId');
        const finAccountSfId = record.value('financialAccountSfId');
        // this.log.debug('update ' + receiptAmount, finAccountId, finAccountSfId, record)();
        expenseData.add(finAccountId ? finAccountId : finAccountSfId, receiptAmount);
      }
    } // all items

    projectData.process(projectMap);
    activityData.process(activityMap);
    expenseData.process(finAccountMap);
  } // update

  /**
   * Create Form Attachment Field
   */
  private static attachUiFormField(dataTable: DataTable, name: string = 'Attachment', label: string = 'Attachment'): UiFormField {
    const column = new DataColumn();
    column.name = name;
    column.label = label;
    column.dataType = DataType.BLOB;
    column.inputType = 'file';
    // accept (image/*) - labelButton (Upload Image)
    // column.controlType; column.inputRegex;
    // dataTable.columnListMap[column.name.toLocaleLowerCase()] = column; // readOnly
    //
    const ff = new UiFormField();
    ff.dataColumn = column;
    ff.isActive = true;
    ff.isDisplay = true;
    ModelUtil.processUiFormField(ff); // sets (property) name
    if (label) { // overwrite
      ff.label = label;
    }
    return ff;
  } // attachUiFormField

  /**
   * Create UI Clone for Timesheet
   */
  private static cloneUiTs(ui: UiTab, rv: UiTab,
                           isSf: boolean, shPj: boolean, shPjPh: boolean, shPjLn: boolean,
                           shAcct: boolean, shCont: boolean, shAT: boolean, shInv: boolean,
                           startDate: Date, showWeekends: boolean) {
    // ref
    TEItemUtil.cloneUiGridRef(rv, isSf, shPj, shPjPh, shPjLn, shAcct, shCont, shAT, shInv);

    // days
    for (let i = 0; i < 7; i++) {
      const dd = new Date(startDate.getTime() + (i * DateUtil.ONEDAY));
      const cn = 'x' + (i + 1);
      const weekend = DateUtil.isWeekend(dd);
      const gc: UiGridField = ModelUtil.cloneUiGridField(rv.dataTable, cn, Trl.formatDateDayUtc(dd));
      if (!showWeekends && weekend) {
        gc.isDisplay = false; // hide but still contain
      }
      rv.gridFieldList.push(gc);
    }
    // total
    const gct: UiGridField = ModelUtil.cloneUiGridField(rv.dataTable, 'x0');
    rv.gridFieldList.push(gct);

    // rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'tedate'));
    // rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'status'));

    // description
    const des = ModelUtil.cloneUiGridField(rv.dataTable, 'description');
    // des.dataColumn.lengthMin = 2; // TEST
    // des.dataColumn.length = 4;
    // des.dataColumn.isUiRequired = true;
    rv.gridFieldList.push(des);
    if (shInv) {
      rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'invoiced'));
    }
    // link
    rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'id'));

  } // cloneUiTs

  /**
   * Grid References
   */
  private static cloneUiGridRef(rv: UiTab,
                                isSf: boolean, shPj: boolean, shPjPh: boolean, shPjLn: boolean,
                                shAcct: boolean, shCont: boolean, shAT: boolean, shInv: boolean) {
    if (isSf) {
      if (shPj) {
        rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'projectsfid'));
      }
      if (shPjPh) {
        rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'projectphasesfid'));
      }
      if (shPjLn) {
        rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'projectlinesfid'));
      }
      if (shAcct) {
        rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'accountsfid'));
      }
      if (shCont) {
        rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'contactsfid'));
      }
      if (shAT) {
        rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'activitytypesfid'));
      }
    } else {
      if (shPj) {
        rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'projectid'));
      }
      if (shPjLn) {
        rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'projectlineid'));
      }
      if (shAT) {
        rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'activitytypeid'));
      }
    }
  } // cloneUiGridRef

  /**
   * Create UI Clone for Expense Table
   */
  private static cloneUiEt(ui: UiTab, rv: UiTab,
                           isSf: boolean, shPj: boolean, shPjPh: boolean, shPjLn: boolean,
                           shAcct: boolean, shCont: boolean, shAT: boolean, shInv: boolean) {
    const tempAQ = ModelUtil.cloneUiGridField(rv.dataTable, 'tempAQ');
    tempAQ.nameFooter = 'expenseAmount'; // PerDiem - show expenseAmount if not tempAQ
    rv.gridFieldList.push(tempAQ);
    if (isSf) {
      rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'financialAccountSfId'));
    } else {
      rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'financialAccountId'));
    }
    // description
    const des = ModelUtil.cloneUiGridField(rv.dataTable, 'description');
    // des.dataColumn.lengthMin = 2; // TEST
    // des.dataColumn.length = 4;
    // des.dataColumn.isUiRequired = true;
    rv.gridFieldList.push(des);

    rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'receiptDate'));
    // ref
    TEItemUtil.cloneUiGridRef(rv, isSf, shPj, shPjPh, shPjLn, shAcct, shCont, shAT, shInv);
    // invoiced
    if (shInv) {
      rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'invoiced'));
    }
    // link
    rv.gridFieldList.push(ModelUtil.cloneUiGridField(rv.dataTable, 'id'));
  } // cloneUiEt


  /**
   * Form UI for time or expense
   */
  private static cloneUiSingle(ui: UiTab, rv: UiTab, isSf: boolean, what: string,
                               shPj: boolean, shPjPh: boolean, shPjLn: boolean,
                               shAcct: boolean, shCont: boolean, shAT: boolean, shInv: boolean) {
    const formSection = new UiFormSection();
    rv.formSectionList.push(formSection);

    if (what === 'time') {
      formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, TEItemD.hours.n, undefined, true));
      const minutes = ModelUtil.cloneUiFormField(rv.dataTable, TEItemD.minutes.n);
      minutes.description = 'time';
      formSection.uiFormFieldList.push(minutes);
    } else if (what === 'exp') {
      formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, TEItemD.tempAQ.n, undefined, true));
      if (isSf) {
        formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, TEItemD.financialAccountSfId.n, undefined, true));
      } else {
        formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, TEItemD.financialAccountId.n, undefined, true));
      }
    } else if (what === 'inout') {
      const minutes = ModelUtil.cloneUiFormField(rv.dataTable, TEItemD.minutes.n);
      minutes.description = 'inout';
      formSection.uiFormFieldList.push(minutes);
      formSection.uiFormFieldList.push(new UiFormField());
      formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, TEItemD.timeStart.n));
      formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, TEItemD.timeEnd.n));
    }

    // refs
    if (isSf) {
      if (shPj) {
        formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'projectsfid'));
      }
      if (shPjPh) {
        formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'projectphasesfid'));
      }
      if (shPjLn) {
        formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'projectlinesfid'));
      }
      if (shAcct) {
        formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'accountsfid'));
      }
      if (shCont) {
        formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'contactsfid'));
      }
      if (shAT) {
        formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'activitytypesfid'));
      }
    } else { // not sf
      if (shPj) {
        formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'projectid'));
      }
      if (shPjLn) {
        formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'projectlineid'));
      }
      if (shAT) {
        formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'activitytypeid'));
      }
    }

    if (what !== 'ref') {
      const des = ModelUtil.cloneUiFormField(rv.dataTable, 'description');
      formSection.uiFormFieldList.push(des);
    }
    if (shInv) {
      formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'invoiced'));
    }
    if (what === 'ref') {
      return; // ref only
    }

    // date
    if (what === 'exp') {
      formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'receiptDate'));
    } else if (what === 'time') {
      formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, TEItemD.teDate.n));
    }
    // attachment
    formSection.uiFormFieldList.push(TEItemUtil.attachUiFormField(rv.dataTable));

    // link
    formSection.uiFormFieldList.push(ModelUtil.cloneUiFormField(rv.dataTable, 'id'));
  } // cloneUiSingle

  /**
   * Get hours or set to 0
   * @param record data record
   * @param i index 1..7
   */
  private static getHours(record: DataRecord, i: number): number {
    const xString = record.value('x' + i);
    if (xString && xString.length > 0) {
      return Number(xString);
    }
    record.changeMap[ 'x' + i ] = '0';
    return 0;
  } // getHours

  /**
   * Update (add) project/activity data
   * @param startDate start date
   * @param dayOffset offset in days
   * @param value the value
   * @param record item reference
   * @param projectData updated data (add)
   * @param activityData updated data (add)
   */
  private static updateDay(
    startDate: Date, dayOffset: number,
    value: number,
    record: DataRecord,
    projectData: BarChartStackedData,
    activityData: BarChartStackedData
  ) {
    const time = startDate.getTime() + (dayOffset * DateUtil.ONEDAY);

    const projectId = record.value('projectId');
    const projectSfId = record.value('projectSfId');
    projectData.add(new Date(time), projectId ? projectId : projectSfId, value);
    // this.log.debug('updateDay ' + value, projectId, record )();

    const activityTypeId = record.value('activityTypeId');
    const activityTypeSfId = record.value('activityTypeSfId');
    activityData.add(new Date(time), activityTypeId ? activityTypeId : activityTypeSfId, value);
  } // updateDay


} // TEItemUtil
