import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';

import { AccortoService, DataRecord, Logger, ModelUtil, UiTab } from 'accorto';
import { TEItemUtil } from '../model/t-e-item-util';
import { TrackItemService } from '../track-item/track-item.service';
import { CResponseTrack } from '../model/c-response-track';

/**
 * Expense Entry
 */
@Component({
  selector: 't4d-expense',
  templateUrl: './expense.component.html',
  styleUrls: [ './expense.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ExpenseComponent implements OnInit, OnDestroy {

  ui: UiTab = new UiTab();
  record: DataRecord = new DataRecord();

  /** Child Form */
  // @ViewChild(FormComponent, { static: false }) theForm: FormComponent;

  /** busy */
  busy: boolean = true;
  /** messages */
  message: string;
  error: string;

  private log: Logger = new Logger('Expense');
  private subscriptions: Subscription[] = [];
  private originalUi: UiTab;


  /**
   * Expense entry
   */
  constructor(private route: ActivatedRoute,
              private conf: AccortoService,
              private service: TrackItemService) {
  }

  get showDelete(): boolean {
    return this.record && this.record.id && this.record.id.length > 0;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit() {
    // ui
    this.subscriptions.push(this.route.data.subscribe((data => {
      const ui = data.uiTab;
      // this.log.debug('uiTab', ui)();
      if (ui) {
        this.originalUi = ui;
        this.initUi();
        this.record = ModelUtil.newDataRecord(ui);
        this.busy = false;
      }
    })));
    // parameter
    this.subscriptions.push(this.route.queryParams.subscribe((params: Params) => {
      const id = params.id;
      if (id) {
        this.log.debug('ngInit params', id)();
        this.service.queryId(id).subscribe((response: CResponseTrack) => {
          this.message = response.message;
          this.error = response.error;
          if (response.records && response.records.length > 0) {
            this.setRecord(response.records[ 0 ]);
          }
        });
      }
    }));
  } // ngOnInit

  /**
   * Delete
   */
  onDelete() {
    if (this.record.id) {
      this.service.delete(undefined, [ this.record ], undefined)
        .subscribe((response: CResponseTrack) => {
          this.message = response.message;
          this.error = response.error;
          if (!this.error) {
            this.onNew();
            this.message = response.message;
          }
        });
    }
  } // onDelete

  onNew() {
    this.record = ModelUtil.newDataRecord(this.ui);
    this.message = undefined;
    this.error = undefined;
    this.log.info('onNew', this.record)();
  }

  /**
   * Save Record - called from Form/Record
   * @param record record to be saved
   */
  saveRecord(record: DataRecord) {
    this.log.info('saveRecord', record)();
    this.busy = true;
    this.service.saveRecord(record, undefined)
      .subscribe((response: CResponseTrack) => {
        this.message = response.message;
        this.error = response.error;
        if (response.records && response.records.length > 0) {
          this.record = response.records[ 0 ];
          this.log.debug('saveRecord.result', this.record)();
          //  this.router.navigate([ '/ui', this.ui.name, this.record.id ]);
        }
        this.busy = false;
      });
  } // saveRecord

  statusUpdate(msg: string) {
    this.log.info('statusUpdate', msg)();
    this.message = msg;
  }

  private initUi() {
    if (this.originalUi) {
      this.ui = TEItemUtil.cloneUi(this.originalUi, 'exp',
        true, this.conf.session.settings);
    }
  } // initUi

  private setRecord(record: DataRecord) {
    this.record = record;
  }

} // ExpenseComponent
