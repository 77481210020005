import { Account } from 'accorto';
import { TenantUser } from 'accorto';
import { ActivityType } from 'accorto';
import { Resource } from 'accorto';
import { DataRecordI } from 'accorto';
import { DataRecordF } from 'accorto';
import { ProjectLine } from 'accorto';
import { Contact } from 'accorto';
import { Project } from 'accorto';
import { ProjectPhase } from 'accorto';
import { Tenant } from 'accorto';
import { TEItemI, TEItemD } from './t-e-item-i';
import { FinancialAccount } from './financial-account';
import { DataRecord } from 'accorto';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: TEItem
 */
export class TEItem {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id: number|string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created: number;

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy: TenantUser;

  public createdById: number;

  /**
   * label: Updated Time
   */
  public updated: number;

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy: TenantUser;

  public updatedById: number;

  /**
   * label: External Id
   */
  public externalId: string;

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant: Tenant;

  public tenantId: number;

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner: TenantUser;

  public ownerId: number;

  /**
   * label: Record Version
   */
  public version: number;

  /**
   * label: Salesforce Id
   */
  public sfId: string;

  /**
   * label: Status
   */
  public status: string;

  /**
   * label: Billable
   */
  public invoiced: string;

  /**
   * 90 (min) - 1:30 (hours:minutes) - 1h30m = 1.5 (decimal)
   * help: Converted to decimal hours
   * label: Hours
   */
  public hours: number;

  /**
   * Minutes
   * label: Minutes
   */
  public minutes: number;

  /**
   * label: Start Time
   */
  public timeStart: number;

  /**
   * label: End Time
   */
  public timeEnd: number;

  /**
   * label: Date
   */
  public teDate: number;

  /**
   * label: Receipt Amount/Quantity
   */
  public tempAQ: number;

  /**
   * label: Receipt Date
   */
  public receiptDate: number;

  /**
   * label: Receipt Amount
   */
  public receiptAmount: number;

  /**
   * label: Receipt Quantity
   */
  public receiptQuantity: number;

  /**
   * label: Currency
   */
  public currencyCode: string;

  /**
   * label: Expense Amount
   */
  public expenseAmount: number;

  /**
   * fk FinancialAccount
   * label: Fin Account
   */
  public financialAccount: FinancialAccount;

  public financialAccountId: number;

  /**
   * label: Import Source
   */
  public sourceImport: string;

  /**
   * label: Fin Account
   */
  public financialAccountSfId: string;

  /**
   * fk Project
   * label: Project
   */
  public project: Project;

  public projectId: number;

  /**
   * label: Project
   */
  public projectSfId: string;

  /**
   * fk ProjectPhase
   * label: Project Phase
   */
  public projectPhase: ProjectPhase;

  public projectPhaseId: number;

  /**
   * label: Project Phase
   */
  public projectPhaseSfId: string;

  /**
   * fk ActivityType
   * label: Activity Type
   */
  public activityType: ActivityType;

  public activityTypeId: number;

  /**
   * fk ProjectLine
   * label: Project Line
   */
  public projectLine: ProjectLine;

  public projectLineId: number;

  /**
   * label: Activity Type
   */
  public activityTypeSfId: string;

  /**
   * label: Project Line
   */
  public projectLineSfId: string;

  /**
   * fk Account
   * label: Account
   */
  public account: Account;

  public accountId: number;

  /**
   * label: Account
   */
  public accountSfId: string;

  /**
   * fk Contact
   * label: Contact
   */
  public contact: Contact;

  public contactId: number;

  /**
   * label: Contact
   */
  public contactSfId: string;

  /**
   * fk Resource
   * label: Resource
   */
  public resource: Resource;

  public resourceId: number;

  /**
   * label: Resource
   */
  public resourceSfId: string;

  /**
   * label: Sun
   */
  public x1: number;

  /**
   * label: Sun
   */
  public d1: string;

  /**
   * label: Mon
   */
  public x2: number;

  /**
   * label: Mon
   */
  public d2: string;

  /**
   * label: Tue
   */
  public x3: number;

  /**
   * label: Tue
   */
  public d3: string;

  /**
   * label: Wed
   */
  public x4: number;

  /**
   * label: Wed
   */
  public d4: string;

  /**
   * label: Thu
   */
  public x5: number;

  /**
   * label: Thu
   */
  public d5: string;

  /**
   * label: Fri
   */
  public x6: number;

  /**
   * label: Fri
   */
  public d6: string;

  /**
   * label: Sat
   */
  public x7: number;

  /**
   * label: Sat
   */
  public d7: string;

  /**
   * Total hours
   * label: Sum
   */
  public x0: number;

  public latitude: number;

  public longitude: number;

  public costRate: number;

  public billingRate: number;


  /**
   * @return TEItem record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'TEItem';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key) => {
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): TEItem {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        const value = record.valueMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, TEItemD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        const value = record.changeMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, TEItemD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: TEItemI): TEItem {
    Object.keys(TEItemD).forEach((key) => {
      const value = record[ key ];
      if (value) {
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'financialAccount') { // fk
          this.financialAccount = new FinancialAccount();
          this.financialAccount.setFromI(value);
        } else if (key === 'project') { // fk
          this.project = new Project();
          this.project.setFromI(value);
        } else if (key === 'projectPhase') { // fk
          this.projectPhase = new ProjectPhase();
          this.projectPhase.setFromI(value);
        } else if (key === 'activityType') { // fk
          this.activityType = new ActivityType();
          this.activityType.setFromI(value);
        } else if (key === 'projectLine') { // fk
          this.projectLine = new ProjectLine();
          this.projectLine.setFromI(value);
        } else if (key === 'account') { // fk
          this.account = new Account();
          this.account.setFromI(value);
        } else if (key === 'contact') { // fk
          this.contact = new Contact();
          this.contact.setFromI(value);
        } else if (key === 'resource') { // fk
          this.resource = new Resource();
          this.resource.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // TEItem
