import { ProjectI } from 'accorto';
import { ActivityTypeI } from 'accorto';
import { AccountI } from 'accorto';
import { TenantUserI } from 'accorto';
import { ProjectPhaseI } from 'accorto';
import { ResourceI } from 'accorto';
import { ProjectLineI } from 'accorto';
import { FinancialAccountI } from './financial-account-i';
import { ContactI } from 'accorto';
import { TenantI } from 'accorto';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: TEItem
 */
export interface TEItemI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name?: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * label: Status
   */
  status?: string;

  /**
   * label: Billable
   */
  invoiced?: string;

  /**
   * 90 (min) - 1:30 (hours:minutes) - 1h30m = 1.5 (decimal)
   * help: Converted to decimal hours
   * label: Hours
   */
  hours?: number;

  /**
   * Minutes
   * label: Minutes
   */
  minutes?: number;

  /**
   * label: Start Time
   */
  timeStart?: number;

  /**
   * label: End Time
   */
  timeEnd?: number;

  /**
   * label: Date
   */
  teDate?: number;

  /**
   * label: Receipt Amount/Quantity
   */
  tempAQ?: number;

  /**
   * label: Receipt Date
   */
  receiptDate?: number;

  /**
   * label: Receipt Amount
   */
  receiptAmount?: number;

  /**
   * label: Receipt Quantity
   */
  receiptQuantity?: number;

  /**
   * label: Currency
   */
  currencyCode?: string;

  /**
   * label: Expense Amount
   */
  expenseAmount?: number;

  /**
   * fk FinancialAccountI
   * label: Fin Account
   */
  financialAccount?: FinancialAccountI;

  /**
   * fk FinancialAccountI key
   * label: Fin Account
   */
  financialAccountId?: number|string;

  /**
   * label: Import Source
   */
  sourceImport?: string;

  /**
   * label: Fin Account
   */
  financialAccountSfId?: string;

  /**
   * fk ProjectI
   * label: Project
   */
  project?: ProjectI;

  /**
   * fk ProjectI key
   * label: Project
   */
  projectId?: number|string;

  /**
   * label: Project
   */
  projectSfId?: string;

  /**
   * fk ProjectPhaseI
   * label: Project Phase
   */
  projectPhase?: ProjectPhaseI;

  /**
   * fk ProjectPhaseI key
   * label: Project Phase
   */
  projectPhaseId?: number|string;

  /**
   * label: Project Phase
   */
  projectPhaseSfId?: string;

  /**
   * fk ActivityTypeI
   * label: Activity Type
   */
  activityType?: ActivityTypeI;

  /**
   * fk ActivityTypeI key
   * label: Activity Type
   */
  activityTypeId?: number|string;

  /**
   * fk ProjectLineI
   * label: Project Line
   */
  projectLine?: ProjectLineI;

  /**
   * fk ProjectLineI key
   * label: Project Line
   */
  projectLineId?: number|string;

  /**
   * label: Activity Type
   */
  activityTypeSfId?: string;

  /**
   * label: Project Line
   */
  projectLineSfId?: string;

  /**
   * fk AccountI
   * label: Account
   */
  account?: AccountI;

  /**
   * fk AccountI key
   * label: Account
   */
  accountId?: number|string;

  /**
   * label: Account
   */
  accountSfId?: string;

  /**
   * fk ContactI
   * label: Contact
   */
  contact?: ContactI;

  /**
   * fk ContactI key
   * label: Contact
   */
  contactId?: number|string;

  /**
   * label: Contact
   */
  contactSfId?: string;

  /**
   * fk ResourceI
   * label: Resource
   */
  resource?: ResourceI;

  /**
   * fk ResourceI key
   * label: Resource
   */
  resourceId?: number|string;

  /**
   * label: Resource
   */
  resourceSfId?: string;

  /**
   * label: Sun
   */
  x1?: number;

  /**
   * label: Sun
   */
  d1?: string;

  /**
   * label: Mon
   */
  x2?: number;

  /**
   * label: Mon
   */
  d2?: string;

  /**
   * label: Tue
   */
  x3?: number;

  /**
   * label: Tue
   */
  d3?: string;

  /**
   * label: Wed
   */
  x4?: number;

  /**
   * label: Wed
   */
  d4?: string;

  /**
   * label: Thu
   */
  x5?: number;

  /**
   * label: Thu
   */
  d5?: string;

  /**
   * label: Fri
   */
  x6?: number;

  /**
   * label: Fri
   */
  d6?: string;

  /**
   * label: Sat
   */
  x7?: number;

  /**
   * label: Sat
   */
  d7?: string;

  /**
   * Total hours
   * label: Sum
   */
  x0?: number;

  latitude?: number;

  longitude?: number;

  costRate?: number;

  billingRate?: number;

} // TEItem

/** TEItem Definition */
export const TEItemD = {
  recordTypeName: 'TEItem',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  status: {
    n: 'status',
    t: 'string'
  },
  invoiced: {
    n: 'invoiced',
    t: 'string'
  },
  hours: {
    n: 'hours',
    t: 'number'
  },
  minutes: {
    n: 'minutes',
    t: 'number'
  },
  timeStart: {
    n: 'timeStart',
    t: 'number'
  },
  timeEnd: {
    n: 'timeEnd',
    t: 'number'
  },
  teDate: {
    n: 'teDate',
    t: 'number'
  },
  tempAQ: {
    n: 'tempAQ',
    t: 'number'
  },
  receiptDate: {
    n: 'receiptDate',
    t: 'number'
  },
  receiptAmount: {
    n: 'receiptAmount',
    t: 'number'
  },
  receiptQuantity: {
    n: 'receiptQuantity',
    t: 'number'
  },
  currencyCode: {
    n: 'currencyCode',
    t: 'string'
  },
  expenseAmount: {
    n: 'expenseAmount',
    t: 'number'
  },
  financialAccount: {
    n: 'financialAccount',
    t: 'FinancialAccountI'
  },
  financialAccountId: {
    n: 'financialAccountId',
    t: 'number|string'
  },
  sourceImport: {
    n: 'sourceImport',
    t: 'string'
  },
  financialAccountSfId: {
    n: 'financialAccountSfId',
    t: 'string'
  },
  project: {
    n: 'project',
    t: 'ProjectI'
  },
  projectId: {
    n: 'projectId',
    t: 'number|string'
  },
  projectSfId: {
    n: 'projectSfId',
    t: 'string'
  },
  projectPhase: {
    n: 'projectPhase',
    t: 'ProjectPhaseI'
  },
  projectPhaseId: {
    n: 'projectPhaseId',
    t: 'number|string'
  },
  projectPhaseSfId: {
    n: 'projectPhaseSfId',
    t: 'string'
  },
  activityType: {
    n: 'activityType',
    t: 'ActivityTypeI'
  },
  activityTypeId: {
    n: 'activityTypeId',
    t: 'number|string'
  },
  projectLine: {
    n: 'projectLine',
    t: 'ProjectLineI'
  },
  projectLineId: {
    n: 'projectLineId',
    t: 'number|string'
  },
  activityTypeSfId: {
    n: 'activityTypeSfId',
    t: 'string'
  },
  projectLineSfId: {
    n: 'projectLineSfId',
    t: 'string'
  },
  account: {
    n: 'account',
    t: 'AccountI'
  },
  accountId: {
    n: 'accountId',
    t: 'number|string'
  },
  accountSfId: {
    n: 'accountSfId',
    t: 'string'
  },
  contact: {
    n: 'contact',
    t: 'ContactI'
  },
  contactId: {
    n: 'contactId',
    t: 'number|string'
  },
  contactSfId: {
    n: 'contactSfId',
    t: 'string'
  },
  resource: {
    n: 'resource',
    t: 'ResourceI'
  },
  resourceId: {
    n: 'resourceId',
    t: 'number|string'
  },
  resourceSfId: {
    n: 'resourceSfId',
    t: 'string'
  },
  x1: {
    n: 'x1',
    t: 'number'
  },
  d1: {
    n: 'd1',
    t: 'string'
  },
  x2: {
    n: 'x2',
    t: 'number'
  },
  d2: {
    n: 'd2',
    t: 'string'
  },
  x3: {
    n: 'x3',
    t: 'number'
  },
  d3: {
    n: 'd3',
    t: 'string'
  },
  x4: {
    n: 'x4',
    t: 'number'
  },
  d4: {
    n: 'd4',
    t: 'string'
  },
  x5: {
    n: 'x5',
    t: 'number'
  },
  d5: {
    n: 'd5',
    t: 'string'
  },
  x6: {
    n: 'x6',
    t: 'number'
  },
  d6: {
    n: 'd6',
    t: 'string'
  },
  x7: {
    n: 'x7',
    t: 'number'
  },
  d7: {
    n: 'd7',
    t: 'string'
  },
  x0: {
    n: 'x0',
    t: 'number'
  },
  latitude: {
    n: 'latitude',
    t: 'number'
  },
  longitude: {
    n: 'longitude',
    t: 'number'
  },
  costRate: {
    n: 'costRate',
    t: 'number'
  },
  billingRate: {
    n: 'billingRate',
    t: 'number'
  },
}; // TEItem
