const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** TEItem Definition */
export const TEItemD = {
    recordTypeName: 'TEItem',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    status: {
        n: 'status',
        t: 'string'
    },
    invoiced: {
        n: 'invoiced',
        t: 'string'
    },
    hours: {
        n: 'hours',
        t: 'number'
    },
    minutes: {
        n: 'minutes',
        t: 'number'
    },
    timeStart: {
        n: 'timeStart',
        t: 'number'
    },
    timeEnd: {
        n: 'timeEnd',
        t: 'number'
    },
    teDate: {
        n: 'teDate',
        t: 'number'
    },
    tempAQ: {
        n: 'tempAQ',
        t: 'number'
    },
    receiptDate: {
        n: 'receiptDate',
        t: 'number'
    },
    receiptAmount: {
        n: 'receiptAmount',
        t: 'number'
    },
    receiptQuantity: {
        n: 'receiptQuantity',
        t: 'number'
    },
    currencyCode: {
        n: 'currencyCode',
        t: 'string'
    },
    expenseAmount: {
        n: 'expenseAmount',
        t: 'number'
    },
    financialAccount: {
        n: 'financialAccount',
        t: 'FinancialAccountI'
    },
    financialAccountId: {
        n: 'financialAccountId',
        t: 'number|string'
    },
    sourceImport: {
        n: 'sourceImport',
        t: 'string'
    },
    financialAccountSfId: {
        n: 'financialAccountSfId',
        t: 'string'
    },
    project: {
        n: 'project',
        t: 'ProjectI'
    },
    projectId: {
        n: 'projectId',
        t: 'number|string'
    },
    projectSfId: {
        n: 'projectSfId',
        t: 'string'
    },
    projectPhase: {
        n: 'projectPhase',
        t: 'ProjectPhaseI'
    },
    projectPhaseId: {
        n: 'projectPhaseId',
        t: 'number|string'
    },
    projectPhaseSfId: {
        n: 'projectPhaseSfId',
        t: 'string'
    },
    activityType: {
        n: 'activityType',
        t: 'ActivityTypeI'
    },
    activityTypeId: {
        n: 'activityTypeId',
        t: 'number|string'
    },
    projectLine: {
        n: 'projectLine',
        t: 'ProjectLineI'
    },
    projectLineId: {
        n: 'projectLineId',
        t: 'number|string'
    },
    activityTypeSfId: {
        n: 'activityTypeSfId',
        t: 'string'
    },
    projectLineSfId: {
        n: 'projectLineSfId',
        t: 'string'
    },
    account: {
        n: 'account',
        t: 'AccountI'
    },
    accountId: {
        n: 'accountId',
        t: 'number|string'
    },
    accountSfId: {
        n: 'accountSfId',
        t: 'string'
    },
    contact: {
        n: 'contact',
        t: 'ContactI'
    },
    contactId: {
        n: 'contactId',
        t: 'number|string'
    },
    contactSfId: {
        n: 'contactSfId',
        t: 'string'
    },
    resource: {
        n: 'resource',
        t: 'ResourceI'
    },
    resourceId: {
        n: 'resourceId',
        t: 'number|string'
    },
    resourceSfId: {
        n: 'resourceSfId',
        t: 'string'
    },
    x1: {
        n: 'x1',
        t: 'number'
    },
    d1: {
        n: 'd1',
        t: 'string'
    },
    x2: {
        n: 'x2',
        t: 'number'
    },
    d2: {
        n: 'd2',
        t: 'string'
    },
    x3: {
        n: 'x3',
        t: 'number'
    },
    d3: {
        n: 'd3',
        t: 'string'
    },
    x4: {
        n: 'x4',
        t: 'number'
    },
    d4: {
        n: 'd4',
        t: 'string'
    },
    x5: {
        n: 'x5',
        t: 'number'
    },
    d5: {
        n: 'd5',
        t: 'string'
    },
    x6: {
        n: 'x6',
        t: 'number'
    },
    d6: {
        n: 'd6',
        t: 'string'
    },
    x7: {
        n: 'x7',
        t: 'number'
    },
    d7: {
        n: 'd7',
        t: 'string'
    },
    x0: {
        n: 'x0',
        t: 'number'
    },
    latitude: {
        n: 'latitude',
        t: 'number'
    },
    longitude: {
        n: 'longitude',
        t: 'number'
    },
    costRate: {
        n: 'costRate',
        t: 'number'
    },
    billingRate: {
        n: 'billingRate',
        t: 'number'
    },
}; // TEItem
export { ɵ0 };
