import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormBuilder } from '@angular/forms';
import { UiTab } from '../model/ui-tab';
import { DataRecord } from '../model/data-record';
import { Logger } from '../log/logger';
import { DataType } from '../model/data-column';
import { UiGridField } from '../model/ui-grid-field';
import { AccortoService } from '../accorto.service';
import { FormManager } from '../form/form-manager';
import { ModelUtil } from '../utils/model-util';
import { FkUtil } from '../fk/fk-util';
import { Preference } from '../utils/preference';
import { ModalService } from '../modal/modal.service';
import { ModalInfo } from '../modal/modal-info';
import { DataService } from '../data/data.service';
import { CResponseData } from '../model/c-response-data';
import { NotificationService } from '../notification/notification.service';
import { FkService } from '../fk/fk.service';

/**
 * Data Table
 * - input: ui, records
 * - output: saveRecords
 * - handles: new, delete
 */
@Component({
  selector: 'acc-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: [ './data-table.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class DataTableComponent implements OnInit, OnChanges {


  /** UiTab */
  @Input() ui: UiTab;
  /** Data */
  @Input() records: DataRecord[];
  /** Table Edit Mode */
  @Input() editMode: boolean = true;
  /** scrollable_x (cuts off dropdown) */
  @Input() scrollX: boolean = false;
  /** Save changed/new records */
  @Output() saveRecords = new EventEmitter<DataRecord[]>();

  formManagers: FormManager[] = [];

  /** Something to save */
  saveDisabled: boolean = true;
  private log: Logger = new Logger('DataTable');
  private recordChanged = new EventEmitter<FormManager>();

  /*
  Resizable Table
  https://gist.github.com/sfcure/d46652c1ba17070fcbfa4343e0f9943f
   */

  /**
   * Data Table
   */
  constructor(private fb: FormBuilder,
              private store: Store<any>,
              private conf: AccortoService,
              private notify: NotificationService,
              private fkService: FkService,
              private dataService: DataService,
              private modalService: ModalService) {
    this.ui = new UiTab();
    this.records = [];

    // record changed
    this.recordChanged.subscribe((fm) => {
      this.onRowChanged(fm);
    });
    // scrollable_x cuts off dropdown
    this.scrollX = Preference.prefFkType.value !== 'list';
  }

  get debug(): boolean {
    return this.conf.isDebug;
  }

  /**
   * Get Footer Value (expense amount for quantity)
   */
  footerValue(gf: UiGridField, record: DataRecord) {
    if (gf.nameFooter) { // e.g. expenseAmt
      const valueFooter = record.value(gf.nameFooter);
      const value = record.value(gf.name); // e.g. tempAQ
      if (value !== valueFooter) {
        return valueFooter;
      }
    }
    return undefined;
  }

  idFor(part: string, index?: number) {
    if (index !== undefined) {
      return this.ui.uiId + '-' + part + '-' + index;
    }
    return this.ui.uiId + '-' + part;
  }

  // show delete button
  isDelete(fm: FormManager): boolean {
    if (fm.record.isReadOnly || !fm.record.isActive) {
      return false;
    }
    return true;
  }

  /**
   * build table and preload fk's
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.ui && this.ui.gridFieldList && this.ui.gridFieldList.length > 0
      && this.records) { // have grid fields and records
      this.buildTable();
    }
    if (changes.ui) { // preload fk's
      FkUtil.preloadGridFKs(this.store, this.ui, this.records);
    }
  } // ngOnChanges

  ngOnInit() {
  }

  onDelete(fm: FormManager) {
    const indexFm = this.formManagers.indexOf(fm);
    const indexRecord = this.records.indexOf(fm.record);
    if (indexFm !== undefined && indexFm === indexRecord) {
      if (fm.record.id) {
        const modalInfo = new ModalInfo(
          'Delete ' + this.ui.label + '?',
          'Delete ' + fm.record.name + '?');
        this.modalService.doConfirm(modalInfo,
          () => {
            this.log.info('onDelete confirmed fm=' + indexFm + ' record=' + indexRecord)();
            this.dataService.deleteRecord(fm.record).subscribe((response: CResponseData) => {
              if (response.error) {
                this.notify.addError(response.error, response.message);
              } else {
                this.formManagers.splice(indexFm, 1);
                this.records.splice(indexRecord, 1);
                this.notify.addSuccess('Deleted Record', fm.record.name);
              }
            });
          },
          () => {
          }
        );
      } else { // not saved yet
        this.log.info('onDelete fm=' + indexFm + ' record=' + indexRecord)();
        this.formManagers.splice(indexFm, 1);
        this.records.splice(indexRecord, 1);
      }
    }
  } // onDelete

  /**
   * new record
   */
  onNew() {
    let rowNo = -1;
    this.records.forEach((rec) => {
      if (!rec.id) {
        rowNo -= 1;
      }
    });
    const dr = ModelUtil.newDataRecord(this.ui, rowNo);
    this.log.info('onNew', dr)();
    this.records.push(dr);
    this.formManagers.push(new FormManager(this.fb, this.ui, dr, this.editMode ? 'y' : 'n', this.conf,
      null, null, this.recordChanged));
  } // onNew

  onReset() {
    this.log.info('onReset')();
    this.formManagers.forEach((fm) => {
      fm.onReset();
    });
  } // onReset

  /**
   * Row changed - saveDisabled
   */
  onRowChanged(fm: FormManager) {
    let unchanged: boolean = true;
    let someError: boolean = false;
    this.formManagers.forEach((fm1) => {
      // if (!fm1.recordUnchanged) {
      if (fm.changed) {
        unchanged = false;
      }
      if (!fm1.recordValid) {
        someError = true;
      }
    });
    this.saveDisabled = someError || unchanged;
    this.log.info('onRowChanged ' + fm.name, 'unchanged=' + unchanged, 'error=' + someError)();
    this.updateFooter(); // footer
  } // onRowChanged

  // Row Select Clicked
  onRowSelected(row: DataRecord) {
    row.isSelected = !row.isSelected;
    this.log.debug('onRowSelected', row)();
  }


  /**
   * Save
   */
  onSave() {
    const records: DataRecord[] = [];
    this.formManagers.forEach((fm: FormManager) => {
      if (fm.isChanged) {
        records.push(fm.record);
      }
    });
    this.log.info('onSave', records)();
    if (records.length > 0) {
      this.saveRecords.emit(records);
    }
  } // onSave

  getSortArrow(gc: UiGridField): string {
    const icon = gc.sortUp === undefined ? 'sort' : (gc.sortUp ? 'arrowup' : 'arrowdown');
    return '/assets/icons/utility-sprite/svg/symbols.svg#' + icon;
  }

  /**
   * Sort by field
   * @param event ignored
   * @param gf grid field
   */
  onSort(event: MouseEvent, gf: UiGridField) {
    const order = gf.sortUp === undefined ? 1 : gf.sortUp ? 1 : -1; // 1 = asc
    // this.log.log('onSort ' + gf.name + ' up=' + gf.sortUp + ' order=' + order)();

    this.formManagers.sort((one: FormManager, two: FormManager) => {
      const v1 = one.record.value(gf.name);
      const v2 = two.record.value(gf.name);
      // console.log('sort', v1, v2);
      if (!v1 && !v2) {
        //  console.log('sort !v1 && !v2', v1, v2);
        return 0;
      }
      if (!v1) {
        //  console.log('sort !v1', v1, v2);
        return -order;
      }
      if (!v2) {
        //  console.log('sort !v2', v1, v2);
        return order;
      }
      // actual values
      const v1l = this.onSortLabel(gf, v1);
      const v2l = this.onSortLabel(gf, v2);
      // number
      const v1n = Number(v1l);
      if (!Number.isNaN(v1n)) {
        const v2n = Number(v2l);
        if (!Number.isNaN(v2n)) {
          const cmp = v1n - v2n;
          if (!Number.isNaN(cmp)) {
            // console.log('sort # ' + cmp, v1l, v2l);
            return cmp * order;
          }
        }
      }
      // console.log('sort @ ' + v1l.localeCompare(v2l), v1l, v2l);
      return v1l.localeCompare(v2l) * order; // non ascii
    });
    for (const gc of this.ui.gridFieldList) {
      gc.sortUp = undefined;
    }
    gf.sortUp = order === -1; // desc
  } // onSort

  /**
   * Build Header/Footer
   * - column: isActive = include - !isDisplay = hide
   */
  private buildTable() {
    this.log.setSubName(this.ui.name);

    const meta: string = this.records.length > 0 ? this.records[ 0 ].meta : '';
    this.log.log('buildTable', 'meta=' + meta);
    // readonly - server

    this.formManagers = [];
    for (const record of this.records) {
      // isActive - can only change isActive
      this.formManagers.push(new FormManager(this.fb, this.ui, record, this.editMode ? 'y' : 'n', this.conf,
        null, null, this.recordChanged));
      // break; // TEST
    }

    // columns
    for (const col of this.ui.gridFieldList) {
      if (col.isActive == null) {
        col.isActive = true;
      }
      if (col.isDisplay == null) {
        col.isDisplay = true;
      }
      if (!col.isActive) {
        continue;
      }
      // header
      col.headerInfo = col.label;
      const column = col.dataColumn;
      if (column) {
        if (column.description) {
          col.headerInfo += ': ' + column.description;
        }
        if (column.help) {
          col.headerInfo += ' - ' + column.help;
        }
      } else {
        console.warn('NoDataColumn', col, this.ui);
      }
      col.sortUp = undefined;
    } // col
    this.updateFooter();
  } // buildTable

  /**
   * @param record data record
   * @return record number +1 for new
   */
  rowNo(record: DataRecord): string {
    if (record.rowNo !== undefined) {
      if (record.rowNo < 0) { // new
        const rowNo = record.rowNo * -1;
        return '+' + String(rowNo);
      }
      return String(record.rowNo + 1);
    }
    return '+';
  } // nowNo

  // Select Title
  selectRow(index: number): string {
    return 'Select record ' + (index + 1);
  }

  /**
   * Get Display Value
   * @param gf field
   * @param vv raw value
   * @return label value
   */
  private onSortLabel(gf: UiGridField, vv: string): string {
    if (gf.dataColumn && gf.dataColumn.isFk) {
      return this.fkService.getLabel(gf.dataColumn.fkTable, vv);
    }
    return vv;
  } // onSortLabel

  /**
   * Update Footer
   */
  private updateFooter() {
    for (const col of this.ui.gridFieldList) {
      if (!col.dataColumn) {
        col.footerInfo = '~~';
        console.warn('DataTable.updateFooter noDataColumn', col);
        continue;
      }
      const dt = col.dataColumn.dataType;
      // -- records
      let notNull: number = 0;
      let total: number;
      let min: number;
      let max: number;
      for (const record of this.records) {
        const v = col.nameFooter ? record.value(col.nameFooter) : record.value(col.name);
        if (v) {
          notNull++;
          if (dt === DataType.BOOLEAN) {
            if (v === 'true') {
              total++;
            }
          } else if (dt === DataType.DECIMAL || dt === DataType.HOURS
            || dt === DataType.INTEGER) {
            if (total === undefined) {
              total = 0;
            }
            const vv = Number(v);
            total += vv;
            if (min === undefined || min > vv) {
              min = vv;
            }
            if (max === undefined || max < vv) {
              max = vv;
            }
          } else if (dt === DataType.DATE) {
          }
        }
      } // records

      // footer
      if (total) { // round to 2 digits
        const noString = String(total);
        const ii = noString.indexOf('.');
        if (ii >= 0 && (noString.length - ii) > 3) {
          total = Number(total.toFixed(2));
        }
      }
      col.total = total !== undefined ? total : notNull;
      col.footerInfo = col.label + ': #' + notNull;
      if (min !== undefined) {
        col.footerInfo += ' min=' + min;
      }
      if (max !== undefined) {
        col.footerInfo += ' max=' + max;
      }
      if (total !== undefined) {
        col.footerInfo += ' total=' + total;
      }
      col.footerInfo += ' (' + dt.toLowerCase() + ')';
    } // for each col/field

  } // updateFooter

} // DataTable
