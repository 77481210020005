import { CRequest } from './c-request';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CRequest
 * name: LoginRequest
 */
export class LoginRequest extends CRequest {

  /**
   * label: User email
   */
  public email: string;

  /**
   * label: User password
   */
  public pw: string;

  /**
   * label: Salesforce Auth Server
   */
  public sfAuth: string;

  /**
   * label: Salesforce Endpoint
   */
  public sfEndpoint: string;

  /**
   * label: Salesforce User Password
   */
  public sfPw: string;

  /**
   * label: Salesforce Session Id
   */
  public sfSid: string;

  /**
   * label: Salesforce User Token
   */
  public sfToken: string;

  /**
   * label: Salesforce Username
   */
  public sfUn: string;

  /**
   * label: User name
   */
  public un: string;

  /**
   * label: Forgot Password
   */
  public isForgotPassword: boolean;

} // LoginRequest
