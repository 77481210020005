import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AccortoService, DataRecord, DataTable, FormManager, Logger, ModelUtil, UiFormField, UiFormSection, UiTab } from 'accorto';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TrackState } from '../track-item/track-item.reducer';
import { TrackItemService } from '../track-item/track-item.service';
import { Subscription } from 'rxjs';
import { TEItemUtil } from '../model/t-e-item-util';
import { CResponseTrack } from '../model/c-response-track';
import { Track4dType } from '../model/c-request-track';
import { TEItemD } from '../model/t-e-item-i';

/**
 * Expenses
 * - parameter: startTimeMs, endTimeMs
 */
@Component({
  selector: 't4d-expense-table',
  templateUrl: './expense-table.component.html',
  styleUrls: [ './expense-table.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ExpenseTableComponent implements OnInit, OnDestroy {

  /** ui definition */
  ui: UiTab = new UiTab();
  /** timesheet record list */
  records: DataRecord[] = [];


  queryFM: FormManager;
  queryUi: UiTab = new UiTab();
  queryRecord: DataRecord = new DataRecord();
  fromFF: UiFormField;
  toFF: UiFormField;

  /** Busy spinner */
  busy: boolean = true;
  message: string;
  error: string;

  private subscriptions: Subscription[] = [];
  private log: Logger = new Logger('ExpenseTable');
  private originalUi: UiTab;

  /**
   * Expenses
   */
  constructor(private route: ActivatedRoute,
              private store: Store<TrackState>,
              private router: Router,
              private conf: AccortoService,
              private service: TrackItemService,
              private fb: FormBuilder) {
  }

  /**
   * Query Expenses
   */
  doQuery() {
    if (this.queryFM) { // initialized by initUi
      this.queryFM.onFocus('query');
    }
    //
    const fromDate = this.queryRecord.changeMap.fromDate;
    const toDate = this.queryRecord.changeMap.toDate;
    const startTimeMs = fromDate ? Number(fromDate) : undefined;
    const endTimeMs = toDate ? Number(toDate) : undefined;
    //
    this.doQuery2(startTimeMs, endTimeMs);
  } // doQuery

  /**
   * Save Records
   * @param records records
   */
  doSaveRecords(records: DataRecord[]) {
    this.message = 'saving ...';
    this.error = undefined;
    this.busy = true;
    //
    this.log.info('doSaveRecords', records)();
    this.service.save(undefined, records, Track4dType.EXPENSES)
      .subscribe((response: CResponseTrack) => {
        this.message = response.message;
        this.error = response.error;
        this.records = response.records;
        // add new record
        this.records.push(ModelUtil.newDataRecord(this.ui));
        this.busy = false;
      });
  } // doSaveRecords

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit() {
    // ui
    this.subscriptions.push(this.route.data.subscribe((data => {
      const ui = data.uiTab;
      this.log.debug('ngOnInit uiTab', ui)();
      if (ui) {
        this.originalUi = ui;
        this.initUi();
      }
    })));

    // parameters
    this.subscriptions.push(this.route.queryParams.subscribe((params: Params) => {
      // display/set parameters
      let startTimeMs;
      if (params.startTimeMs) {
        this.queryFM.formGroup.patchValue({
          fromDate: params.startTimeMs
        });
        startTimeMs = Number(params.startTimeMs);
      }
      let endTimeMs;
      if (params.endTimeMs) {
        this.queryFM.formGroup.patchValue({
          toDate: params.endTimeMs
        });
        endTimeMs = Number(params.endTimeMs);
      }
      this.log.debug('ngOnInit params', params, startTimeMs, endTimeMs)();

      this.doQuery2(startTimeMs, endTimeMs);
    }));

  } // ngOnInit

  /**
   * Do Query
   */
  private doQuery2(startTimeMs: number, endTimeMs: number, isEditable: boolean = true) {
    this.message = 'querying ...';
    this.error = undefined;
    this.busy = true;
    //
    this.log.info('doQuery2', startTimeMs, endTimeMs, isEditable)();
    this.service.queryExpenses(startTimeMs, endTimeMs)
      .subscribe((result: CResponseTrack) => {
        this.message = result.message;
        this.error = result.error;
        this.records = result.records;
        // add new record
        this.records.push(ModelUtil.newDataRecord(this.ui));
        this.busy = false;
      });
  }

  /**
   * Create Ui
   */
  private initUi() {
    if (this.originalUi) {
      this.ui = TEItemUtil.cloneUi(this.originalUi, 'et',
        true, this.conf.session.settings, undefined, undefined);
      // query input
      const queryTable = new DataTable();
      queryTable.name = 'query';
      const fromCol = ModelUtil.cloneDataColumn(this.originalUi.dataTable, TEItemD.teDate.n,
        queryTable, 'fromDate', 'From Date');
      const toCol = ModelUtil.cloneDataColumn(this.originalUi.dataTable, TEItemD.teDate.n,
        queryTable, 'toDate', 'To');
      // this.log.debug('initUi cols', fromCol, toCol)();
      this.queryUi.name = 'query';
      this.queryUi.dataTable = queryTable;
      //
      const formSection = new UiFormSection();
      this.queryUi.formSectionList.push(formSection);
      this.fromFF = ModelUtil.cloneUiFormField(this.queryUi.dataTable, 'fromDate');
      formSection.uiFormFieldList.push(this.fromFF);
      this.toFF = ModelUtil.cloneUiFormField(this.queryUi.dataTable, 'toDate');
      formSection.uiFormFieldList.push(this.toFF);
      //
      this.queryRecord.rowNo = 0;
      this.queryFM = new FormManager(this.fb, this.queryUi, this.queryRecord, undefined, this.conf, undefined, undefined, undefined);
      // this.log.debug('initUi fm', this.queryFM)();
    }
  } // initUi

} // ExpenseTableComponent
