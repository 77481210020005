import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { Logger } from '../log/logger';
import { DataColumn } from '../model/data-column';
import { UiFormField } from '../model/ui-form-field';
import { UiGridField } from '../model/ui-grid-field';
import { FormManager } from '../form/form-manager';
import { FormFocus } from '../form/form-focus';
import { FkInfo } from '../model/fk-info';

/**
 * Search
 */
@Component({
  selector: 'acc-form-search',
  templateUrl: './form-search.component.html',
  styleUrls: [ './form-search.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class FormSearchComponent implements OnChanges, OnDestroy, FormFocus {

  /** Grid Field */
  @Input() gf: UiGridField;
  /** Form Field */
  @Input() ff: UiFormField;
  /** Form Manager */
  @Input() fm: FormManager;

  /** Base Id */
  @Input() theId: string;
  /** ReadOnly Overwrite */
  @Input() readonly: boolean;

  /** Valid Options */
  @Input() options: FkInfo[] = [];

  /** The String Number Value */
  value: string | undefined;

  /** disabled */
  isDisabled: boolean = false;

  @ViewChild('fkar', { static: false })
  autoReductionElement: ElementRef;
  @ViewChildren('fkarli')
  autoReductionList: QueryList<ElementRef<HTMLLIElement>>;

  /** Property Name */
  propertyName: string;
  /** Field Label */
  label: string;
  /** Data Column */
  dataColumn: DataColumn;

  /** Current Value - FK Info */
  fkInfo: FkInfo = new FkInfo();
  /** optional Value */
  optionalFkInfo = new FkInfo().empty();

  /** Form Element Control */
  control: FormControl;

  errorMessage: string;

  /** Show AutoReduction */
  showAutoReduction: boolean = false;
  /** Hover vs Click */
  useHover: boolean = false;

  private subscription: Subscription;
  private log: Logger = new Logger('FormSearch');

  /**
   * Search
   */
  constructor(private sanitizer: DomSanitizer) {
    this.optionalFkInfo.validForSelection = true;
  }

  get isError(): boolean {
    return !!this.errorMessage;
  }

  /**
   * Required
   */
  get isUiRequired(): boolean {
    if (this.ff) {
      return this.ff.isRequired();
    }
    if (this.gf && this.gf.dataColumn) {
      return this.gf.dataColumn.isUiRequired;
    }
    return false;
  }

  // Read only
  get isReadOnly(): boolean {
    if (this.readonly !== undefined && this.readonly) {
      return true; // override
    }
    if (this.fm && this.fm.record.isReadOnly) {
      return true; // record
    }
    if (this.dataColumn) {
      return this.dataColumn.isReadOnly; // column
    }
    return false;
  }

  /** ** **
   * Display
   * @param fkInfo new value
   */
  public displayFkInfo(fkInfo?: FkInfo) {
    const label = fkInfo ? fkInfo.label : '';
    this.fkInfo = fkInfo ? fkInfo : this.optionalFkInfo; // sets span + a
    // set auto reduction
    if (this.autoReductionElement) {
      // this.log.debug('displayFkInfo auto ' + label)();
      this.autoReductionElement.nativeElement.value = label;
    }
  } // displayFkInfo

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ff) {
      this.propertyName = this.ff.name;
      this.label = this.ff.label;
      this.dataColumn = this.ff.dataColumn;
    } else if (this.gf) {
      this.propertyName = this.gf.name;
      this.label = this.gf.label;
      this.dataColumn = this.gf.dataColumn;
    }
    if (!this.control) { // setup
      this.log.setSubName(this.propertyName);
      this.fm.registerFocus(this.propertyName, this);
      this.control = this.fm.formGroup.controls[ this.propertyName ] as FormControl;
      this.subscription = this.control.valueChanges.subscribe((value) => {
        this.log.debug('ValueChanges error=' + this.errorMessage,
          (this.control.valid ? '' : 'NotValid ') + 'value=' + value)();
        this.setFkValue(value);
      });
      // this.optionalFkInfo.validForSelection = this.isUiRequired;
    }
    if (this.control) {
      const value = this.control.value;
      this.log.debug('ngOnChanges', changes,
        (this.control.dirty ? 'dirty ' : '') + (this.control.valid ? '' : 'NotValid ') + 'value=' + value)();
      this.setFkValue(this.control.value);
    } else {
      this.log.info('ngOnChanges NoControl ' + this.propertyName, this.fm.formGroup)();
    }
  } // ngOnChanges

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * AutoReduction - click on clear button
   * = clear selection and focus
   */
  onAutoReductionClear(event: MouseEvent) {
    this.log.debug('onAutoReductionClear', event)();
    this.onAutoReductionSelect(undefined);
    this.showAutoReduction = true;
    this.autoReductionElement.nativeElement.focus();
  } // onAutoReductionClear

  /**
   * AutoReduction - click in field = show/hide dropdown
   */
  onAutoReductionClick(event: MouseEvent) {
    this.showAutoReduction = !this.showAutoReduction;
    this.log.debug('onAutoReductionClick show=' + this.showAutoReduction, event)();

    if (this.showAutoReduction) { // scroll
      setTimeout(() => { // not displayed yet
        this.onAutoReductionScroll(undefined);
      }, 100);
    } else {
      //  this.useHover = false;
    }
  } // onAutoReductionClick

  /**
   * AutoReduction - Arrow Up/Down = move selection
   */
  onAutoReductionKeyArrow(down: boolean) {
    let found = false;
    let index;
    for (let x = 0; x < this.options.length; x++) {
      const i = down ? x : this.options.length - x - 1;
      const op = this.options[ i ];
      // console.log('#' + i, op.isMatched);
      if (op.isMatched) {
        if (found) { // previous found
          op.isSelected = true;
          index = i;
          // this.log.debug('onAutoReductionKeyArrow-' + (down ? 'Down' : 'Up') + index, op.label)();
          found = undefined;
          break;
        }
        if (op.isSelected) {
          found = true;
          op.isSelected = false;
        }
      }
    } // forAll
    if (found !== undefined) { // found->lastOne !found->none
      for (let x = 0; x < this.options.length; x++) {
        const i = down ? x : this.options.length - x - 1;
        const op = this.options[ i ];
        if (op.isMatched) {
          op.isSelected = true; // select first
          index = i;
          // this.log.debug('onAutoReductionKeyArrow=' + (down ? 'Down' : 'Up') + index, op.label)();
          break;
        }
      }
    }
    this.onAutoReductionScroll(index);
  } // onAutoReductionKeyArrow

  /**
   * AutoReduction - check for Tab Key
   */
  onAutoReductionKeyDown(event: KeyboardEvent) {
    // this.log.debug('onAutoReductionKeyDown ' + event.key)();
    if (event.key === 'Tab') {
      this.onAutoReductionKeyEnter();
    }
  }

  /**
   * AutoReduction - Enter/Tab = select selected or first matched or none
   */
  onAutoReductionKeyEnter() {
    this.log.debug('onAutoReductionKeyEnter')();
    this.showAutoReduction = false;
    for (const op of this.options) { // selected
      if (op.isSelected) {
        this.onAutoReductionSelect(op);
        return;
      }
    }
    for (const op of this.options) { // matched
      if (op.isMatched) {
        this.onAutoReductionSelect(op);
        return;
      }
    }
    this.onAutoReductionSelect(undefined);
  } // onAutoReductionKeyEnter

  /**
   * AutoReduction - handle keyboard event = update dropdown
   */
  onAutoReductionKeyUp(event: KeyboardEvent) {
    this.showAutoReduction = true;
    const target = event.target as HTMLInputElement;
    let searchTerm = target.value; // this.autoReductionElement.nativeElement.value;
    this.log.debug('onAutoReductionKeyUp ' + event.key, searchTerm)();
    // special keys
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Home' || event.key === 'End') {
      return; // no term change

    } else if (event.key === 'Escape' || event.key === 'Clear') { // ** Esc
      searchTerm = ''; // clear w/o triggering change
      target.value = searchTerm; // this.autoReductionElement.nativeElement.value = searchTerm;
      for (const op of this.options) {
        op.isSelected = false;
      }

    } else if (event.key === 'ArrowUp') { // ** Up
      this.onAutoReductionKeyArrow(false);
      return;

    } else if (event.key === 'ArrowDown') { // ** Down
      this.onAutoReductionKeyArrow(true);
      return;

    } else if (event.key === 'Enter') { // ** Enter
      this.onAutoReductionKeyEnter();
      target.blur(); // nextSobling is dropdown
      return;

    }
    // update dropdown based on searchTerm
    let count = 0;
    let foundSelected = 'n'; // no
    for (const op of this.options) {
      if (op.match(searchTerm, this.sanitizer)) {
        count++;
      } else {
        op.isSelected = false; // not matching
        continue;
      }
      if (foundSelected === '-') {
        op.isSelected = false; // following
      } else if (foundSelected === 'y') {
        op.isSelected = true; // first
        foundSelected = '-';
      } else if (op.isSelected) {
        foundSelected = 'y'; // next matching
      }
    }
    this.errorMessage = undefined;
    if (count === 0) {
      if (searchTerm) {
        this.errorMessage = 'no match';
      }
      this.showAutoReduction = false;
    }
  } // onAutoReductionKeyUp

  /**
   * AutoReduction = scroll to selected
   */
  onAutoReductionScroll(index: number) {
    if (!this.autoReductionList) {
      return;
    }
    let currentIndex = 0;
    let heightOffset = 0;
    let parent;
    let foundSelected = false;
    this.autoReductionList.forEach((ref) => {
      const li = ref.nativeElement;
      if (!parent) {
        parent = li.parentNode.parentNode as HTMLDivElement; // li>ul>div
      }
      const div = li.firstChild as HTMLElement;
      if (index === undefined) {
        if (!foundSelected) {
          foundSelected = div.getAttribute('aria-selected') === 'true'; // previous dom update
          heightOffset += li.getBoundingClientRect().height;
        }
        if (!foundSelected) {
          currentIndex++;
        }
      } else {
        if (currentIndex < index) {
          heightOffset += li.getBoundingClientRect().height;
          currentIndex++;
        }
      }
    });
    if (parent && (foundSelected || index !== undefined)) {
      const ph = parent.getBoundingClientRect().height;
      parent.scrollTop = heightOffset - ph / 2;
      this.log.debug('onAutoReductionScroll', '#' + currentIndex + ' ' + heightOffset + ' ' + ph + ' = ' + parent.scrollTop)();
    }
  } // onAutoReductionScroll

  /**
   * AutoReduction - select option
   * - hit enter in control
   * - click on Dropdown option = select
   */
  onAutoReductionSelect(op: FkInfo) {
    this.log.debug('onAutoReductionSelect', op)();
    this.options.forEach((op0) => {
      op0.isSelected = false;
    });
    if (op) {
      op.isSelected = true;
      this.control.setValue(op.fkId); // ** update control **
      this.control.markAsDirty();
      this.displayFkInfo(op);
    } else {
      this.control.setValue(undefined);
      this.control.markAsDirty();
      this.displayFkInfo(this.optionalFkInfo);
    }

    this.showAutoReduction = false;
    this.autoReductionElement.nativeElement.blur();
    if (this.useHover) {
      this.useHover = false; // hide dropdown
      setTimeout(() => {
        this.useHover = true;
      }, 250);
    }
  } // onAutoReductionSelect

  onFocus() {
    this.fm.onFocus(this.propertyName);
  }

  onFocusChangedTo(propertyName: string) {
    if (this.propertyName !== propertyName) {
    }
  }

  private find(fkId: string): FkInfo {
    for (const fk of this.options) {
      if (fk.fkId === fkId) {
        return fk;
      }
    }
    return undefined;
  }

  /** ** ** **
   * Set + Validate fkId - called from ngOnChange
   */
  private setFkValue(fkId: string) {
    const fkInfo = fkId ? this.find(fkId) : this.optionalFkInfo.clone(false);
    // const controlFkId = this.control.value;
    // this.log.info('setFkValue =' + fkId + ' (' + controlFkId + ')', fkInfo, this.control.errors)();
    this.errorMessage = undefined;
    if (this.control.errors) { // reset
      //  this.control.setErrors(null);
    }
    if (fkInfo) { // info found
      if (!fkInfo.validForSelection) {
        this.errorMessage = 'Invalid Selection: ' + fkInfo.label;
        this.control.setErrors({ invalidSelection: this.errorMessage });
        // this.log.info('setFkValue', this.errorMessage, fkInfo)();
      } else if (fkInfo.fkId === '' && this.dataColumn.isUiRequired) {
        this.errorMessage = 'Required';
        this.control.setErrors({ required: true });
        // this.log.info('setFkValue', 'Required')();
      }
    } else { // not found
      this.fkInfo.label = '?' + fkId + '?';
      this.fkInfo.description = 'invalid';
      this.fkInfo.validForSelection = false;
      this.errorMessage = 'Not Found id=' + fkId;
      this.control.setErrors({ invalidFk: fkId });
      this.log.info('setFkValue', 'invalid=' + fkId)();
    }
    this.displayFkInfo(fkInfo); // update ui
  } // setFkValue

} // FormSearchComponent
