
/* tslint:disable max-line-length no-inferrable-types */

export enum WhereOp {
  BETWEEN = 'BETWEEN',
  EQ = 'EQ',
  GE = 'GE',
  GT = 'GT',
  IN = 'IN',
  LE = 'LE',
  LIKE = 'LIKE',
  LT = 'LT',
  NULL = 'NULL',
  NotEQ = 'NotEQ',
  NotNULL = 'NotNULL',
  ThisWEEK = 'ThisWEEK'
}

/**
 * name: DataViewWhere
 */
export interface DataViewWhereI {

  /**
   * e.g. projectId or projectSfId
   * label: ColumnName
   */
  columnName?: string;

  /**
   * label: Operator
   */
  op?: WhereOp;

  /**
   * label: Single Value
   */
  value?: string;

  /**
   * label: Lookup
   */
  valueLabel?: string;

  /**
   * label: Values
   */
  valueList?: string[];

  /**
   * label: Where clause direct
   */
  whereDirect?: string;

} // DataViewWhere

/** DataViewWhere Definition */
export const DataViewWhereD = {
  recordTypeName: 'DataViewWhere',
  columnName: {
    n: 'columnName',
    t: 'string'
  },
  op: {
    n: 'op',
    t: 'WhereOp'
  },
  value: {
    n: 'value',
    t: 'string'
  },
  valueLabel: {
    n: 'valueLabel',
    t: 'string'
  },
  valueList: {
    array: true,
    n: 'valueList',
    t: 'string'
  },
  whereDirect: {
    n: 'whereDirect',
    t: 'string'
  },
}; // DataViewWhere
