
/* tslint:disable max-line-length no-inferrable-types */

export enum WhereOp {
  BETWEEN = 'BETWEEN',
  EQ = 'EQ',
  GE = 'GE',
  GT = 'GT',
  IN = 'IN',
  LE = 'LE',
  LIKE = 'LIKE',
  LT = 'LT',
  NULL = 'NULL',
  NotEQ = 'NotEQ',
  NotNULL = 'NotNULL',
  ThisWEEK = 'ThisWEEK'
}

/**
 * name: DataViewWhere
 */
export class DataViewWhere {

  /**
   * e.g. projectId or projectSfId
   * label: ColumnName
   */
  public columnName: string;

  /**
   * label: Operator
   */
  public op: WhereOp;

  /**
   * label: Single Value
   */
  public value: string;

  /**
   * label: Lookup
   */
  public valueLabel: string;

  /**
   * label: Values
   */
  public valueList: string[] = [];

  /**
   * label: Where clause direct
   */
  public whereDirect: string;


  /**
   * set equals where clause
   */
  public setEquals(columnName: string, value: string): DataViewWhere {
    this.columnName = columnName;
    this.op = WhereOp.EQ;
    this.value = value;
    return this;
  } // setEquals

} // DataViewWhere
