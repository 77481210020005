import { Action, createReducer, on } from '@ngrx/store';
import { DataRecord } from 'accorto';
import * as TrackItemActions from './track-item.actions';

export interface TrackState {
  status: string;
  startTimeMs: number;
  startTime: Date;
  records: DataRecord[];
}

export const initialState: TrackState = {
  status: undefined,
  startTimeMs: undefined,
  startTime: undefined,
  records: undefined
};


const trackReducer0 = createReducer(
  initialState,

  on(TrackItemActions.trackItemRequest,
    (state, action) => {
      return {
        ...state,
        status: 'requested',
        startTimeMs: action.startTimeMs
      };
    }),

  on(TrackItemActions.trackItemResponse, (state, action) => {
    return {
      status: action.response.error ? action.response.error : action.response.message ? action.response.message : 'ok',
      startTimeMs: action.response.startTimeMs,
      startTime: action.response.startTimeMs && action.response.startTimeMs > 0 ? new Date(action.response.startTimeMs) : undefined,
      records: action.response.records
    };
  }),

  on(TrackItemActions.trackItemError, (state, action) => {
    console.log('trackReducer.trackError', action);
    return {
      ...state,
      status: 'error'
    };
  })
);

export function trackReducer(state: TrackState | undefined, action: Action): TrackState {
  return trackReducer0(state, action);
} // trackReducer
