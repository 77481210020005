import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { TrackState } from './track-item.reducer';
import { DataRecord } from 'accorto';

export const selectTrackState = createFeatureSelector<TrackState>('track');


export const selectTrackStartTimeMs: MemoizedSelector<TrackState, number> = createSelector(
  selectTrackState,
  trackState => trackState.startTimeMs
);

export const selectTrackStartTime: MemoizedSelector<TrackState, Date> = createSelector(
  selectTrackState,
  trackState => trackState.startTime
);

export const selectTrackRecords: MemoizedSelector<TrackState, DataRecord[]> = createSelector(
  selectTrackState,
  trackState => trackState.records
);
