import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AccortoService } from '../accorto.service';
import { LoginRequest } from '../model/login-request';
import { Logger } from '../log/logger';
import { AccortoCUtil } from '../model/accorto-c-util';
import { ClientStorage } from '../utils/client-storage';
import { CRequest } from '../model/c-request';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../accorto.service";
/**
 * Login Service
 * uses parameter: sid, cid
 * forwards parameter: un, pw, email, sfUn, sfPw, sfToken, sfSid, sfEndpoint
 */
export class LoginService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
        this.log = new Logger('LoginService');
    }
    /**
     * @param req login request - called from LoginEffects - loginRequestAction
     */
    login(req) {
        const request = req ? req : new LoginRequest();
        this.config.setCRequest(request);
        request.sid = this.config.getEnvOrStorage(ClientStorage.SID);
        request.cid = this.config.getEnv('cid');
        // forward info
        request.un = this.config.getEnvOrStorage(ClientStorage.UN);
        request.pw = this.config.getEnv('pw');
        request.email = this.config.getEnv('email');
        request.sfUn = this.config.getEnv('sfUn');
        request.sfPw = this.config.getEnv('sfPw');
        request.sfToken = this.config.getEnv('sfToken');
        request.sfAuth = this.config.getEnvOrStorage(ClientStorage.SFAUTH);
        request.sfSid = this.config.getEnv('sfSid');
        request.sfEndpoint = this.config.getEnv('sfEndpoint');
        return this.send(request);
    } // login
    /**
     * Login un/pw
     * @param un user name
     * @param pw password
     */
    loginUnPw(un, pw) {
        const request = new LoginRequest();
        this.config.setCRequest(request);
        request.un = un;
        request.pw = btoa(pw);
        return this.send(request);
    }
    /**
     * Forgot Password
     * @param un user name
     */
    loginForgotPassword(un) {
        const request = new LoginRequest();
        this.config.setCRequest(request);
        request.un = un;
        request.isForgotPassword = true;
        return this.send(request);
    }
    send(request) {
        const url = this.config.server + '/login';
        this.log.info('send ' + url, request)();
        const body = JSON.stringify(request);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(url, body, httpOptions)
            .pipe(tap(response => {
            this.log.info('send.response', response)(); // json
        }), map(response => AccortoCUtil.createLoginResponse(response)));
    } // send
    /**
     * Logout
     */
    logout() {
        this.log.info('logout')();
        ClientStorage.set(ClientStorage.SID, undefined);
        //
        const request = new CRequest();
        this.config.setCRequest(request);
        //
        this.config.session = null;
        //
        const url = this.config.server + '/logout';
        const body = JSON.stringify(request);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this.http.post(url, body, httpOptions).subscribe((response) => {
            this.log.info('logout.response', response)();
        });
        return of(true);
    }
} // LoginService
/** @nocollapse */ LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AccortoService)); }, token: LoginService, providedIn: "root" });
