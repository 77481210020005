
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: PerDiemItem
 */
export class PerDiemItem {

  /**
   * label: The Date
   */
  public dateMs: number;

  /**
   * label: Per Diem Description
   */
  public description: string;

  /**
   * label: Destination
   */
  public destination: string;

  /**
   * label: Destination Detail
   */
  public destinationDetail: string;

  /**
   * label: Per Diem Destination options
   */
  public destinationOptions: string[] = [];

  /**
   * label: The End Date (for requests)
   */
  public endDateMs: number;

  public isBreakfast: boolean;

  public isDinner: boolean;

  public isIncidental: boolean;

  public isLodging: boolean;

  public isLunch: boolean;

  /**
   * label: Full Rate Meals
   */
  public isMeals: boolean;

  /**
   * label: Reduced Rate Meals
   */
  public isMeals2: boolean;

  /**
   * label: Rate for Breakfast or Reduction
   */
  public rateBreakfast: number;

  /**
   * label: Rate for Dinner or Reduction
   */
  public rateDinner: number;

  public rateIncidental: number;

  /**
   * label: Lodging Rate
   */
  public rateLodging: number;

  /**
   * label: Rate for Lunch or Reduction
   */
  public rateLunch: number;

  /**
   * label: Meals and Incidentals
   */
  public rateMeals: number;

  /**
   * label: Meals reduced
   */
  public rateMeals2: number;

  /**
   * label: record type: gsa, de, ...
   */
  public type: string;

} // PerDiemItem
