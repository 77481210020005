import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AUtil, DateUtil, Logger, Trl, validateDate } from 'accorto';
import { TrackState } from '../track-item/track-item.reducer';
import { selectTrackStartTime } from '../track-item/track-item.selectors';
import { trackItemRequest } from '../track-item/track-item.actions';

/**
 * Select Week - creates store request
 */
@Component({
  selector: 't4d-week-select',
  templateUrl: './week-select.component.html',
  styleUrls: [ './week-select.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class WeekSelectComponent implements OnInit, OnDestroy {

  /** The (initial) Date */
  theDate: Date = new Date();

  @Output() dateSelected = new EventEmitter<Date>();

  showDateDialog: boolean = false;
  dateTitle: string;

  /* Form **/
  weekStart: FormControl = new FormControl('', [ validateDate ]);
  formGroup: FormGroup = new FormGroup({
    weekStart: this.weekStart
  });

  private log: Logger = new Logger('WeekSelect');
  private subscription: Subscription;

  constructor(private store: Store<TrackState>) {
  }

  get errorMessage() {
    return AUtil.getValidationErrors(this.weekStart.errors);
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = undefined;
  }

  public ngOnInit(): void {
    this.subscription = this.store.pipe(select(selectTrackStartTime)).subscribe((date) => {
      if (date) {
        this.setDate(date);
      }
    });
  }

  // Open Date Dialog
  onClick() {
    this.showDateDialog = !this.showDateDialog;
  }

  // from popup
  onDateSelected(utcDate: Date) {
    this.showDateDialog = false;
    this.setDate(utcDate);
    this.log.debug('onDateSelected', this.theDate)();
    this.store.dispatch(trackItemRequest({ startTimeMs: this.theDate.getTime() }));
    this.dateSelected.emit(this.theDate);
  }

  // go to next week
  onWeekNext() {
    this.setDate(new Date(this.theDate.getTime() + DateUtil.ONEWEEK));
    this.log.debug('onWeekNext', this.theDate)();
    this.store.dispatch(trackItemRequest({ startTimeMs: this.theDate.getTime() }));
    this.dateSelected.emit(this.theDate);
  }

  // go to previous week
  onWeekPrevious() {
    this.setDate(new Date(this.theDate.getTime() - DateUtil.ONEWEEK));
    this.log.debug('onWeekPrevious', this.theDate)();
    this.store.dispatch(trackItemRequest({ startTimeMs: this.theDate.getTime() }));
    this.dateSelected.emit(this.theDate);
  }


  private setDate(newDate: Date) {
    // this.log.info('setDate', newDate.toISOString())();
    this.theDate = newDate;
    this.weekStart.setValue(Trl.formatDateUtc(this.theDate));
    this.dateTitle = 'Week of ' + Trl.formatDateDayUtc(this.theDate);
  }

  private parseInput() {
    const time = Date.parse(this.weekStart.value); // need to parse Trl
    if (!Number.isNaN(time)) {
      const local = new Date(this.weekStart.value);
      this.setDate(new Date(Date.UTC(local.getFullYear(), local.getMonth(), local.getDate())));
    }
  }

} // WeekSelectComponent
