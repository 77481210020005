import { Directive, forwardRef, HostListener } from '@angular/core';
import { AbstractControl, FormControl, FormControlDirective, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Trl } from '../utils/trl';
import { DateUtil } from '../utils/date-util';

/**
 * Date Validator
 */
@Directive({
  selector: '[accDateValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // useValue: validateDate,
      useExisting: forwardRef(() => DateValidatorDirective),
      multi: true
    }
  ]
})
export class DateValidatorDirective implements Validator {

  constructor(private directive: FormControlDirective) {
  }

  /**
   * validate input
   */
  validate(control: AbstractControl): ValidationErrors | null {
    return validateDate(control);
  } // validate

  /**
   * format input
   */
  @HostListener('blur')
  onBlur() {
    const fc: FormControl = this.directive.control;
    const value = fc.value;
    // console.info('DateValidator.onBlur', value);
    if (value) {
      const parsed: Date = DateUtil.parseDate(value);
      // console.debug('DateValidator.onBlur', parsed);
      if (parsed) {
        const formatted = Trl.formatDate(parsed);
        fc.setValue(formatted);
      } else {
        fc.setErrors({ 'Invalid date': true });
      }
    }
  } // onBlur

} // DateValidatorDirective

/**
 * date validator function
 */
export function validateDate(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (value) {
    const parsed: Date = DateUtil.parseDate(value);
    // console.log('validateDateFn', value, parsed);
    if (!parsed) {
      return { 'Invalid date': true };
    }
    if (parsed.getFullYear() < 2000) {
      return { 'Invalid year': true };
    }
    if (parsed.getFullYear() > 3000) {
      return { 'Invalid year': true };
    }
  }
  return null;
}
