import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import { reducers } from './reducers';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AccortoModule } from 'accorto';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TimeComponent } from './time/time.component';
import { ExpenseComponent } from './expense/expense.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { TimeKeeperComponent } from './time-keeper/time-keeper.component';
import { trackReducer } from './track-item/track-item.reducer';
import { TrackItemEffects } from './track-item/track-item.effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TrackItemService } from './track-item/track-item.service';
import { WeekSelectComponent } from './week-select/week-select.component';
import { TestComponent } from './test/test.component';
import { DashboardHoursComponent } from './dashboard-hours/dashboard-hours.component';
import { BarChartStackedComponent } from './d3/bar-chart-stacked/bar-chart-stacked.component';
import { BarChartComponent } from './d3/bar-chart/bar-chart.component';
import { PieChartComponent } from './d3/pie-chart/pie-chart.component';
import { InOutComponent } from './in-out/in-out.component';
import { PerDiemComponent } from './per-diem/per-diem.component';
import { ExpenseTableComponent } from './expense-table/expense-table.component';
import { PerDiemDeComponent } from './per-diem-de/per-diem-de.component';

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 't4d' }),
    HttpClientModule,
    ReactiveFormsModule,

    AppRoutingModule,

    AccortoModule.forRoot({
      server: environment.server,
      serverStage: environment.serverStage,
      production: environment.production,
      gaCode: 'UA-32129178-12',
      ts: environment.ts,
      productCode: 'Track4D',
      productTitle: 'Track 4D (Time)'
    }),

    StoreModule.forRoot(reducers, {
      runtimeChecks:
        {
          strictStateImmutability: true, strictActionImmutability: true,
          strictStateSerializability: false, strictActionSerializability: false
        }
    }),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router', routerState: RouterState.Minimal }),
    StoreModule.forFeature('track', trackReducer),
    //
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([ TrackItemEffects ]),
    //
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    //
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    TimeComponent,
    ExpenseComponent,
    TimesheetComponent,
    TimeKeeperComponent,
    WeekSelectComponent,
    TestComponent,
    DashboardHoursComponent,
    BarChartStackedComponent,
    BarChartComponent,
    PieChartComponent,
    InOutComponent,
    PerDiemComponent,
    ExpenseTableComponent,
    PerDiemDeComponent
  ],
  providers: [
    TrackItemService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
