import { TEItem } from './t-e-item';
import { FinancialAccount } from './financial-account';
import { DataRecord } from 'accorto';
import { AccortoUtil } from 'accorto';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * Domain Utility for com.track4d.domain
 */
export class Track4dUtil extends AccortoUtil {


  /**
   * Convert DataRecord to FinancialAccount
   * @return populated FinancialAccount
   * @param dr data record
   */
  static convertFinancialAccount(dr: DataRecord): FinancialAccount {
    const result = this.createFinancialAccount(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createFinancialAccount(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertFinancialAccount


  /**
   * Convert DataRecord to TEItem
   * @return populated TEItem
   * @param dr data record
   */
  static convertTEItem(dr: DataRecord): TEItem {
    const result = this.createTEItem(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createTEItem(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertTEItem


  /**
   * Convert json to FinancialAccount
   * @return populated FinancialAccount
   * @param json json info
   */
  static createFinancialAccount(json: any): FinancialAccount {
    const entity: FinancialAccount = new FinancialAccount();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'code') {
        entity.code = value;
      } else if (key === 'isExpenseReport') {
        entity.isExpenseReport = Boolean(value);
      } else if (key === 'fixedAmount2') {
        entity.fixedAmount2 = Number(value);
      } else if (key === 'isPerDiem') {
        entity.isPerDiem = Boolean(value);
      } else if (key === 'perDiemType') {
        entity.perDiemType = value;
      }
    } // for loop
    return entity;
  } // createFinancialAccount


  /**
   * Convert json to TEItem
   * @return populated TEItem
   * @param json json info
   */
  static createTEItem(json: any): TEItem {
    const entity: TEItem = new TEItem();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'status') {
        entity.status = value;
      } else if (key === 'invoiced') {
        entity.invoiced = value;
      } else if (key === 'hours') {
        entity.hours = Number(value);
      } else if (key === 'minutes') {
        entity.minutes = Number(value);
      } else if (key === 'timeStart') {
        entity.timeStart = Number(value);
      } else if (key === 'timeEnd') {
        entity.timeEnd = Number(value);
      } else if (key === 'teDate') {
        entity.teDate = Number(value);
      } else if (key === 'tempAQ') {
        entity.tempAQ = Number(value);
      } else if (key === 'receiptDate') {
        entity.receiptDate = Number(value);
      } else if (key === 'receiptAmount') {
        entity.receiptAmount = Number(value);
      } else if (key === 'receiptQuantity') {
        entity.receiptQuantity = Number(value);
      } else if (key === 'currencyCode') {
        entity.currencyCode = value;
      } else if (key === 'expenseAmount') {
        entity.expenseAmount = Number(value);
      } else if (key === 'financialAccount') {
        entity.financialAccount = this.createFinancialAccount(value); // dm
      } else if (key === 'financialAccountId') {
        entity.financialAccountId = Number(value);
      } else if (key === 'sourceImport') {
        entity.sourceImport = value;
      } else if (key === 'financialAccountSfId') {
        entity.financialAccountSfId = value;
      } else if (key === 'project') {
        entity.project = this.createProject(value); // dm
      } else if (key === 'projectId') {
        entity.projectId = Number(value);
      } else if (key === 'projectSfId') {
        entity.projectSfId = value;
      } else if (key === 'projectPhase') {
        entity.projectPhase = this.createProjectPhase(value); // dm
      } else if (key === 'projectPhaseId') {
        entity.projectPhaseId = Number(value);
      } else if (key === 'projectPhaseSfId') {
        entity.projectPhaseSfId = value;
      } else if (key === 'activityType') {
        entity.activityType = this.createActivityType(value); // dm
      } else if (key === 'activityTypeId') {
        entity.activityTypeId = Number(value);
      } else if (key === 'projectLine') {
        entity.projectLine = this.createProjectLine(value); // dm
      } else if (key === 'projectLineId') {
        entity.projectLineId = Number(value);
      } else if (key === 'activityTypeSfId') {
        entity.activityTypeSfId = value;
      } else if (key === 'projectLineSfId') {
        entity.projectLineSfId = value;
      } else if (key === 'account') {
        entity.account = this.createAccount(value); // dm
      } else if (key === 'accountId') {
        entity.accountId = Number(value);
      } else if (key === 'accountSfId') {
        entity.accountSfId = value;
      } else if (key === 'contact') {
        entity.contact = this.createContact(value); // dm
      } else if (key === 'contactId') {
        entity.contactId = Number(value);
      } else if (key === 'contactSfId') {
        entity.contactSfId = value;
      } else if (key === 'resource') {
        entity.resource = this.createResource(value); // dm
      } else if (key === 'resourceId') {
        entity.resourceId = Number(value);
      } else if (key === 'resourceSfId') {
        entity.resourceSfId = value;
      } else if (key === 'x1') {
        entity.x1 = Number(value);
      } else if (key === 'd1') {
        entity.d1 = value;
      } else if (key === 'x2') {
        entity.x2 = Number(value);
      } else if (key === 'd2') {
        entity.d2 = value;
      } else if (key === 'x3') {
        entity.x3 = Number(value);
      } else if (key === 'd3') {
        entity.d3 = value;
      } else if (key === 'x4') {
        entity.x4 = Number(value);
      } else if (key === 'd4') {
        entity.d4 = value;
      } else if (key === 'x5') {
        entity.x5 = Number(value);
      } else if (key === 'd5') {
        entity.d5 = value;
      } else if (key === 'x6') {
        entity.x6 = Number(value);
      } else if (key === 'd6') {
        entity.d6 = value;
      } else if (key === 'x7') {
        entity.x7 = Number(value);
      } else if (key === 'd7') {
        entity.d7 = value;
      } else if (key === 'x0') {
        entity.x0 = Number(value);
      } else if (key === 'latitude') {
        entity.latitude = Number(value);
      } else if (key === 'longitude') {
        entity.longitude = Number(value);
      } else if (key === 'costRate') {
        entity.costRate = Number(value);
      } else if (key === 'billingRate') {
        entity.billingRate = Number(value);
      }
    } // for loop
    return entity;
  } // createTEItem

} // Track4dUtil
