import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AccortoService, CResponse, DataRecord, DateUtil, Logger, logoutRequestAction } from 'accorto';
import { CRequestTrack, Track4dType } from '../model/c-request-track';
import { Track4dCUtil } from '../model/track4d-c-util';
import { CResponseTrack } from '../model/c-response-track';
import { PerDiemItem } from '../model/per-diem-item';
import { TEItemUtil } from '../model/t-e-item-util';
import { TrackState } from './track-item.reducer';

@Injectable({
  providedIn: 'root'
})
export class TrackItemService {

  private log: Logger = new Logger('TrackItemService');

  constructor(private http: HttpClient,
              private config: AccortoService,
              private store: Store<TrackState>) {
    // this.log.info('init')();
  }

  /**
   * Delete records
   * @param startTimeMs start time (for re-query)
   * @param records records to delete
   * @param typeReQuery what to re-query
   */
  delete(startTimeMs: number, records: DataRecord[], typeReQuery: Track4dType): Observable<CResponseTrack> {
    const request = new CRequestTrack();
    request.startTimeMs = startTimeMs;
    request.type = Track4dType.DELETE;
    request.records = records;
    request.typeReQuery = typeReQuery;
    return this.send(request);
  }

  /**
   * Save Per Diem Expenses
   * @param items per diem items
   */
  perDiemExpenses(items: PerDiemItem[], record: DataRecord): Observable<CResponseTrack> {
    const request = new CRequestTrack();
    request.perDiemItems = items;
    request.records.push(record);
    request.type = Track4dType.EXPENSES;
    return this.send(request);
  } // query

  /**
   * Query for week
   * @param startTimeMs start time
   */
  query(startTimeMs: number, description: string): Observable<CResponseTrack> {
    const request = new CRequestTrack();
    request.startTimeMs = startTimeMs;
    request.description = description;
    if (!request.startTimeMs) {
      this.setStartTime(request);
    }
    request.type = Track4dType.QUERY;
    return this.send(request);
  } // query

  /**
   * Query single record
   * @param id the idd
   */
  queryId(id: string): Observable<CResponseTrack> {
    const request = new CRequestTrack();
    request.queryId = id;
    request.type = Track4dType.QUERY;
    return this.send(request);
  } // queryId

  /**
   * Query expenses
   * @param startTimeMs start time ms
   * @param endTimeMs end time ms
   * @param isEditable query only editable expenses
   */
  queryExpenses(startTimeMs: number, endTimeMs: number, isEditable: boolean = true): Observable<CResponseTrack> {
    const request = new CRequestTrack();
    if (startTimeMs) {
      request.startTimeMs = startTimeMs;
    }
    if (endTimeMs) {
      request.endTimeMs = endTimeMs;
    }
    request.isEditable = isEditable;
    request.type = Track4dType.EXPENSES;
    return this.send(request);
  } // query

  queryInOut(): Observable<CResponseTrack> {
    const request = new CRequestTrack();
    request.type = Track4dType.CHECKIN;
    return this.send(request);
  }

  /**
   * Query expenses
   * @param item per diem request
   */
  queryPerDiem(item: PerDiemItem): Observable<CResponseTrack> {
    const request = new CRequestTrack();
    request.perDiemItems.push(item);
    request.type = Track4dType.PERDIEM;
    return this.send(request);
  } // queryPerDiem

  /**
   * Save records
   * @param startTimeMs start time (for re-query)
   * @param records records to save
   * @param typeReQuery what to re-query
   */
  save(startTimeMs: number, records: DataRecord[], typeReQuery: Track4dType): Observable<CResponseTrack> {
    const request = new CRequestTrack();
    request.startTimeMs = startTimeMs;
    request.type = Track4dType.SAVE;
    request.typeReQuery = typeReQuery;
    request.records = records;
    return this.send(request);
  }

  /**
   * Save records
   * @param record record to save
   * @param typeReQuery what to re-query
   */
  saveRecord(record: DataRecord, typeReQuery: Track4dType): Observable<CResponseTrack> {
    const request = new CRequestTrack();
    request.type = Track4dType.SAVE;
    request.typeReQuery = typeReQuery;
    request.records.push(record);
    return this.send(request);
  }

  /**
   * @param request track request
   */
  send(request: CRequestTrack): Observable<CResponseTrack> {
    this.config.setCRequest(request);
    //
    const url = this.config.server + '/track';
    this.log.info('send ' + request.type + ' ' + url, request)();
    const body = JSON.stringify(request);
    //
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<CResponse>(url, body, httpOptions)
      .pipe(
        map(response => Track4dCUtil.createCResponseTrack(response)),
        map((response: CResponseTrack) => {
          if (response.isLoggedOut) {
            this.store.dispatch(logoutRequestAction());
          } else if (response.records && response.startTimeMs && response.startTimeMs > 0) {
            const startDate = new Date(response.startTimeMs);
            response.records.forEach((record: DataRecord) => {
              TEItemUtil.toStartDate(record, startDate);
            });
          }
          return response;
        }),
        tap(response => {
          this.log.info('send.response', response)();
        })
      );
  } // send

  /**
   * Submit Timesheet
   * @param startTimeMs start time
   */
  submitTimesheet(startTimeMs: number): Observable<CResponseTrack> {
    const request = new CRequestTrack();
    request.startTimeMs = startTimeMs;
    request.type = Track4dType.SUBMIT;
    request.typeReQuery = Track4dType.QUERY; // time
    return this.send(request);
  } // submitTimesheet

  /**
   * Set Start time from parameter or today
   */
  private setStartTime(request: CRequestTrack) {
    let rawDate = DateUtil.today(); // UTC
    const dateString = this.config.getEnv('date');
    if (dateString) {
      rawDate = DateUtil.parseDate(dateString);
    }
    const startDate = DateUtil.toStartOfWeek(rawDate);
    request.startTimeMs = startDate.getTime();
    request.description = (request.description ? request.description + ' ' : '') + startDate.toISOString();
  } // setStartTime


} // TrackItemService
